<template>
  <div>
    <!--发货单编辑框-->
    <znl-dialog
      v-loading="dialogLoading"
      :close-on-click-modal="false"
      :height="dialogbox.height"
      :is-footer-show="false"
      :title="title"
      :visible="editvisible"
      :width="dialogbox.width"
      class="dialog-stkoutedit-box"
      element-loading-text="数据加载中,请稍后..."
      subhead="(橙色底为必填项)"
      @close="
        (val) => {
          $emit('close', val), onResetStkout();
        }
      "
    >
      <znl-gridmodule
        ref="flexGrid"
        :column-colors="columnColors"
        :columns="columns"
        :edit-row-index="editRowIndex"
        :from-btns="fromBtns"
        :from-height="150"
        :has-znl-btns="false"
        :hasDefaultBeforeNewRow="true"
        :is-alter-ver="true"
        :is-multi-rows-check="true"
        :item-source="itemSource"
        :loading="loading"
        :on-delete-checked-rows="onDeletedRows"
        :on-init="onInit"
        :page-index="pageIndex"
        :page-size="pageSize"
        :row-colors="rowColors"
        :showAddRowButton="showAddRowButton"
        :showAddRowRightMenu="showAddRowRightMenu"
        :summary-columns="summaryColumns"
        :total-count="totalCount"
        :users="$store.state.users"
        class="grid-data-stkoutedit-box m-border-box"
        element-loading-text="正在保存,请稍等..."
        gridtype="action"
        header="编辑发货单"
        height="445px"
        layout-type="other"
        @click.native.stop="autocompleteShow = false"
        @page-loading="
          (d) => {
            $emit('page-loading', d);
          }
        "
        @edit:cell-ended="onCellEdited"
      >
        <!-- 编辑内容 -->
        <div slot="from-module" style="border: 1px solid #d8d8d8">
          <el-row
            class="formContainer"
            justify="space-around"
            style="
              border-bottom: 1px solid #e6e6e6;
              height: 40px;
              line-height: 40px;
            "
            type="flex"
          >
            <el-col :span="12" class="min-preboderleftnone-box">
              <el-row class="form_Row_W100" type="flex">
                <el-col>
                  <znl-input
                    ref="znlAutocomplete"
                    v-model="StkOut.CustomerName"
                    :border="true"
                    :disabled="fromSalesOrderOpen"
                    :fetch-suggestions="querySearch"
                    :is-must-fill="this.IsCustomerNameRequired"
                    :on-icon-click="!fromSalesOrderOpen && onIconClick"
                    :title="this.CustomerNameTitle"
                    custom-item="my-item-zh"
                    form-type="input"
                    layout="left"
                    placeholder="输入客户简称或拼音字母,并按回车键查询"
                    size="mini"
                    tabindex="1"
                    title-width="70px"
                    type="text"
                    width="100%"
                    @select="suggestionsSelect"
                    @keyup.enter.native="onIconClick"
                  >
                    <i
                      slot="suffix"
                      class="iconfont icon-edit_btn_ic"
                      @click="onIconClick"
                    ></i>
                  </znl-input>
                  <autocomplete
                    ref="autocompleteSelect"
                    :search-data="autocompleteSearchData"
                    :visible.sync="autocompleteShow"
                    CompanyTypes="1,3"
                    class="znl-autocomplete-select"
                    @selection-changed="selectionChanged"
                  ></autocomplete>
                </el-col>
              </el-row>
            </el-col>

            <el-col :span="20" class="min-preboder-box">
              <el-row class="form_Row_W100">
                <el-col :span="8">
                  <znl-input
                    v-model="StkOut.BillNo"
                    :border="true"
                    :disabled="!IsManual || !StkOut.IsAdd"
                    :is-must-fill="isMustFill('BillNo')"
                    :on-icon-click="onBillIconClick"
                    form-type="input"
                    layout="left"
                    placeholder="默认自动生成 "
                    size="mini"
                    title="单据编号："
                    title-width="70px"
                    type="text"
                    width="80%"
                  ></znl-input>

                  <el-checkbox
                    width="20%"
                    size="small"
                    v-model="IsManual"
                    :disabled="!StkOut.IsAdd"
                    >手动</el-checkbox
                  >
                </el-col>

                <el-col :span="8">
                  <znl-input
                    ref="ShipTime"
                    v-model="StkOut.ShipTime"
                    :border="true"
                    :disabled="false"
                    :is-must-fill="true"
                    :readonly="false"
                    form-type="datepicker"
                    layout="left"
                    tabindex="8"
                    title="*发货日期："
                    title-width="70px"
                    width="100%"
                  ></znl-input>
                </el-col>

                <el-col :span="8">
                  <znl-input
                    ref="ShipUserID"
                    v-model="StkOut.ShipUserID"
                    :border="true"
                    :disabled="!hasRes('AllowModifyShipper')"
                    :is-must-fill="true"
                    :select-options="selectOptionsAllUserURL"
                    form-type="select"
                    layout="left"
                    placeholder="请选择"
                    popper-class="popperClass"
                    tabindex="9"
                    title="*发货员："
                    title-width="70px"
                    width="100%"
                  ></znl-input>
                </el-col>
              </el-row>
            </el-col>
          </el-row>

          <el-row
            class="formContainer m-borderbg-box"
            justify="space-around"
            type="flex"
          >
            <el-col :span="12" class="min-boderleftnone-box">
              <el-row
                class="form_Row_W100"
                style="margin-bottom: 2px"
                type="flex"
              >
                <el-col>
                  <el-row class="form_Row_W100" type="flex">
                    <el-col>
                      <znl-input
                        ref="Address"
                        v-model="StkOut.Address"
                        :allow-create="true"
                        :border="true"
                        :clearable="true"
                        :disabled="false"
                        :filterable="true"
                        :is-must-fill="isMustFill('Address')"
                        :select-options="selectOptionsAddress"
                        form-type="select"
                        layout="left"
                        placeholder="客户地址 / 收货地址"
                        popper-class="popperClass"
                        tabindex="2"
                        title="地址："
                        title-width="70px"
                        width="100%"
                        @blur="changeAddressInputHandler"
                      ></znl-input>
                    </el-col>
                  </el-row>
                  <el-row class="form_Row_W100" type="flex">
                    <el-col :span="12">
                      <znl-input
                        ref="Contact"
                        v-model="StkOut.Contact"
                        :border="true"
                        :disabled="fromSalesOrderOpen"
                        :is-must-fill="isMustFill('Contact')"
                        form-type="input"
                        layout="left"
                        placeholder
                        tabindex="3"
                        title="联系人："
                        title-width="70px"
                        type="text"
                        width="100%"
                      ></znl-input>
                    </el-col>
                    <el-col :span="12">
                      <znl-input
                        v-model="StkOut.Telephone"
                        :border="true"
                        :disabled="fromSalesOrderOpen"
                        :is-must-fill="isMustFill('Telephone')"
                        form-type="input"
                        layout="left"
                        placeholder
                        tabindex="4"
                        title="联系电话："
                        title-width="70px"
                        type="text"
                        width="100%"
                      ></znl-input>
                    </el-col>
                  </el-row>

                  <el-row class="form_Row_W100" type="flex">
                    <el-col :span="12">
                      <znl-input
                        v-model="StkOut.Mobile"
                        :border="true"
                        :disabled="fromSalesOrderOpen"
                        :is-must-fill="isMustFill('Mobile')"
                        form-type="input"
                        layout="left"
                        placeholder
                        size="mini"
                        tabindex="5"
                        title="手机号码："
                        title-width="70px"
                        type="text"
                        width="100%"
                      ></znl-input>
                    </el-col>
                    <el-col :span="12">
                      <znl-input
                        ref="SalCompany"
                        v-model="StkOut.SalCompany"
                        :border="true"
                        :clearable="true"
                        :disabled="false"
                        :is-must-fill="false"
                        :select-options="selectOptionsUserSubCompany"
                        form-type="select"
                        layout="left"
                        placeholder="请选择"
                        popper-class="popperClass"
                        tabindex="5"
                        title="销售公司："
                        title-width="70px"
                        width="100%"
                      ></znl-input>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </el-col>

            <el-col :span="20" class="min-boder-box">
              <el-row class="form_Row_W100">
                <el-col :span="8">
                  <znl-input
                    ref="ReceiptType"
                    v-model="StkOut.ReceiptType"
                    :border="true"
                    :clearable="true"
                    :is-must-fill="isMustFill('ReceiptType')"
                    :select-options="selectOptionsPaymentTypeOptions"
                    form-type="select"
                    layout="left"
                    placeholder="请选择"
                    popper-class="popperClass"
                    tabindex="10"
                    title="收款方式："
                    title-width="70px"
                    width="100%"
                    @change="receiptTypeChange"
                  ></znl-input>

                  <znl-input
                    ref="CurrencyCode"
                    v-model="StkOut.CurrencyCode"
                    :border="true"
                    :disabled="false"
                    :is-must-fill="true"
                    :select-options="selectOptionsCurrencyCode"
                    form-type="select"
                    layout="left"
                    placeholder="请选择"
                    popper-class="popperClass"
                    tabindex="13"
                    title="*币 种："
                    title-width="70px"
                    width="70%"
                    @change="CurrencyCodeChange"
                  ></znl-input>

                  <znl-input
                    v-model="StkOut.ExchangeRate"
                    :border="true"
                    :disabled="this.IsExchangeRateRMB"
                    :is-must-fill="isMustFill('ExchangeRate')"
                    form-type="input"
                    layout="left"
                    placeholder="汇率"
                    size="mini"
                    tabindex="14"
                    title
                    type="text"
                    width="28%"
                    @input="onExchangeChange"
                  ></znl-input>

                  <znl-input
                    ref="TaxRate"
                    v-model="StkOut.TaxRate"
                    :border="true"
                    :is-must-fill="isMustFill('TaxRate')"
                    :select-options="selectOptionsTaxRate"
                    form-type="select"
                    layout="left"
                    placeholder="请选择"
                    popper-class="popperClass"
                    tabindex="17"
                    title="税 点："
                    title-width="70px"
                    width="100%"
                    @change="CalcTaxRate"
                  ></znl-input>
                </el-col>

                <el-col :span="8">
                  <znl-input
                    v-model="StkOut.ExpectReceiptDate"
                    :border="true"
                    :editable="false"
                    :is-must-fill="isMustFill('ExpectReceiptDate')"
                    form-type="datepicker"
                    layout="left"
                    placeholder
                    tabindex="11"
                    title="预收日期："
                    title-width="70px"
                    width="100%"
                  ></znl-input>

                  <znl-input
                    v-model="StkOut.Amount"
                    :border="true"
                    :disabled="disabledInput"
                    :is-must-fill="isMustFill('Amount')"
                    :readonly="true"
                    form-type="input"
                    layout="left"
                    placeholder
                    size="mini"
                    title="发货额："
                    title-width="70px"
                    type="text"
                    width="100%"
                  ></znl-input>
                  <znl-input
                    v-model="StkOutReceiveAmount"
                    :border="true"
                    :disabled="disabledInput"
                    :is-must-fill="isMustFill('AmountReceivable')"
                    form-type="input"
                    layout="left"
                    placeholder
                    size="mini"
                    tabindex="22"
                    title="应收额："
                    title-width="70px"
                    type="text"
                    width="100%"
                  ></znl-input>
                </el-col>

                <el-col :span="8">
                  <znl-input
                    v-if="systemConfig.hasFinance && hasRes('BatchRec')"
                    ref="CBGUID"
                    v-model="StkOut.CBGUID"
                    :border="true"
                    :clearable="true"
                    :disabled="!StkOut.IsAdd"
                    :is-must-fill="isMustFill('CBGUID')"
                    :select-options="bankAccountNameList"
                    form-type="select"
                    layout="left"
                    placeholder="请选择"
                    popper-class="popperClass"
                    tabindex="12"
                    title="收款账户："
                    title-width="70px"
                    width="100%"
                  ></znl-input>

                  <znl-input
                    ref="ReceivedAmount"
                    v-model="StkOut.ReceivedAmount"
                    :border="true"
                    :disabled="this.isReadonly || !hasRes('BatchRec')"
                    :is-must-fill="isMustFill('ReceivedAmount')"
                    form-type="input"
                    layout="left"
                    placeholder
                    size="mini"
                    tabindex="16"
                    title="实收额："
                    title-width="70px"
                    type="text"
                    width="100%"
                  ></znl-input>

                  <znl-input
                    v-model="StkOut.DiscountAmount"
                    :border="true"
                    :disabled="true"
                    :is-must-fill="isMustFill('DiscountAmount')"
                    form-type="input"
                    layout="left"
                    placeholder
                    size="mini"
                    tabindex="19"
                    title="已折扣："
                    title-width="70px"
                    type="text"
                    width="100%"
                    @clear="
                      () => {
                        StkOut.DiscountAmount = '';
                        CalcStkOutAmoutAndGrossProfit(false);
                      }
                    "
                  ></znl-input>
                </el-col>
              </el-row>
            </el-col>
          </el-row>

          <el-row
            class="formContainer m-borderbg-box"
            justify="space-around"
            type="flex"
          >
            <el-col :span="12" class="min-borderleftbottommnone-box">
              <el-row class="form_Row_W100" type="flex">
                <el-col>
                  <znl-input
                    v-model="StkOut.Remark"
                    :border="true"
                    :is-must-fill="isMustFill('Remark')"
                    :rows="3"
                    class="m-txt-remark"
                    form-type="input"
                    layout="left"
                    placeholder
                    size="mini"
                    tabindex="6"
                    title="发货备注："
                    title-width="70px"
                    type="textarea"
                    width="100%"
                  ></znl-input>
                </el-col>
              </el-row>
            </el-col>

            <el-col :span="20" class="min-boderbottomnone-box">
              <el-row class="form_Row_W100">
                <el-col :span="8">
                  <znl-input
                    ref="TransportCompany"
                    v-model="StkOut.TransportCompany"
                    :border="true"
                    :clearable="true"
                    :is-must-fill="isMustFill('TransportCompany')"
                    :select-options="selectOptionsTransportCompany"
                    form-type="select"
                    layout="left"
                    placeholder="请选择"
                    popper-class="popperClass"
                    tabindex="20"
                    title="快递公司："
                    title-width="70px"
                    width="100%"
                  ></znl-input>

                  <znl-input
                    v-model="StkOut.CustomerOrderNo"
                    :border="true"
                    :disabled="fromSalesOrderOpen"
                    :is-must-fill="isMustFill('CustomerOrderNo')"
                    form-type="input"
                    layout="left"
                    placeholder
                    size="mini"
                    tabindex="23"
                    title="PO 号："
                    title-width="70px"
                    type="text"
                    width="100%"
                  ></znl-input>
                </el-col>

                <el-col :span="8">
                  <znl-input
                    ref="ShippingNo"
                    v-model="StkOut.ShippingNo"
                    :border="true"
                    :is-must-fill="isMustFill('ShippingNo')"
                    form-type="input"
                    layout="left"
                    placeholder
                    size="mini"
                    tabindex="21"
                    title="快递单号："
                    title-width="70px"
                    type="text"
                    width="100%"
                  ></znl-input>
                  <znl-input
                    v-if="
                      getSpecialResourceByCode('ViewBuyPrice') &&
                      !getSpecialResourceByCode('NoSeeProfit')
                    "
                    v-model="StkOut.GrossProfit"
                    :border="true"
                    :disabled="disabledInput"
                    :is-must-fill="isMustFill('GrossProfit')"
                    form-type="input"
                    layout="left"
                    placeholder
                    size="mini"
                    tabindex="22"
                    title="利润额："
                    title-width="70px"
                    type="text"
                    width="100%"
                  ></znl-input>
                </el-col>

                <el-col :span="8">
                  <znl-input
                    v-show="false"
                    v-model="StkOut.ExpressAccount"
                    :border="true"
                    :disabled="false"
                    :is-must-fill="isMustFill('ExpressAccount')"
                    form-type="input"
                    layout="left"
                    placeholder
                    size="mini"
                    tabindex="22"
                    title="快递账号："
                    title-width="70px"
                    type="text"
                    width="100%"
                  ></znl-input>
                  <znl-input
                    ref="OtherFeeAmount"
                    v-model="StkOut.OtherFeeAmount"
                    :border="true"
                    :is-must-fill="isMustFill('OtherFeeAmount')"
                    form-type="input"
                    layout="left"
                    placeholder
                    size="mini"
                    tabindex="18"
                    title="其它费用："
                    title-width="70px"
                    type="text"
                    width="100%"
                    @blur="CalcStkOutAmoutAndGrossProfit(false)"
                    @clear="
                      () => {
                        StkOut.OtherFeeAmount = '';
                        CalcStkOutAmoutAndGrossProfit(false);
                      }
                    "
                  ></znl-input>
                  <znl-input
                    v-model="StkOut.OrderFrom"
                    :border="true"
                    :clearable="true"
                    :is-must-fill="false"
                    :select-options="selsectOptionsDemandSources"
                    form-type="select"
                    layout="left"
                    placeholder
                    size="mini"
                    tabindex="22"
                    title="订单来源："
                    title-width="70px"
                    width="100%"
                  ></znl-input>
                  <!--   v-model="StkOut.OrderFrom" -->
                  <!-- <znl-button
                    :height="22"
                    @click="stkOtherFeesSave(true)"
                    style-type="main"
                    style="float:right;margin-top:4px"
                    :disabled="isDisabled"
                  >
                    <i class="iconfont icon-save_btn_ic"></i>
                    <span>添加其它费用</span>
                  </znl-button>-->
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </div>
        <div slot="from-content">
          <el-checkbox
            v-model="IsUseReserveRemark"
            size="small"
            @change="onUseReserveRemarkValue()"
            >使用备货库存备注
          </el-checkbox>
          <el-checkbox
            v-model="IsContinuousRecord"
            size="small"
            @change="onContinuousRecordValue()"
            >连续录单模式
          </el-checkbox>
          <el-checkbox
            v-if="hasRes('Print') && !hasRes('UnOut')"
            v-model="IsPrint"
            size="small"
            @change="onPrintValue()"
            >保存后并打印
          </el-checkbox>
          <znl-button
            v-if="hasRes('Print')"
            :height="22"
            style-type="mac"
            type="multiple"
          >
            <znl-button-menu @click="onPrint">
              <i class="iconfont icon-print_btn_ic"></i>
              <span>打印</span>
            </znl-button-menu>
            <znl-button-menu
              :width="16"
              tip="打印设置"
              @click="znlPrintVisible = true"
            >
              <i class="iconfont icon-arrow-drop-down_ic"></i>
            </znl-button-menu>
          </znl-button>
          <znl-button
            v-if="!this.StkOut.IsAudited"
            :disabled="isDisabled"
            :height="22"
            style-type="minor"
            @click="stkoutSave"
          >
            <i class="iconfont icon-save_btn_ic"></i>
            <span>保存</span>
          </znl-button>
          <znl-button
            v-if="!this.StkOut.IsAudited && !hasRes('UnOut')"
            :disabled="isDisabled"
            :height="22"
            style-type="main"
            @click="stkoutSaveAndOut"
          >
            <i class="iconfont icon-save_btn_ic"></i>
            <span>保存并出库</span>
          </znl-button>
        </div>

        <znl-print
          :init-param="false"
          :is-get-default-param="printInfo.isGetDefaultParam"
          :loading="printLoading"
          :printInfo="printInfo"
          :visible.sync="znlPrintVisible"
          print-type="STK_Out"
          title="发货单打印设置"
          @set-print-param="setPrintParam"
          @confirm-print="confirmPrint"
        ></znl-print>
      </znl-gridmodule>

      <div v-if="true" style="margin-top: 10px">
        <stock-query
          height="205px"
          @row-dbl-click="
            (row) => {
              setGridData([row]);
            }
          "
        ></stock-query>
      </div>
    </znl-dialog>

    <znl-dialog
      :close-on-click-modal="false"
      :is-footer-show="false"
      :visible="isBulkAddShow"
      class="pasteDataAcquisitionLog"
      height="300px"
      title="从Excel粘贴"
      width="900px"
      @close="isBulkAddShow = false"
    >
      <pasteDataAcquisition
        slot="znl-dialog"
        :columns="setExcelColumns"
        @add-excel-data="addExcelData"
      ></pasteDataAcquisition>
    </znl-dialog>

    <d-company-contact-select
      v-if="dialogCompanySelectVisible"
      :CompanyName="StkOut.CustomerName"
      :searchModel="SelectSearchModel"
      :visible="dialogCompanySelectVisible"
      companyType="1,3"
      title="选择客户"
      @close="
        () => {
          dialogCompanySelectVisible = false;
        }
      "
      @confirm="companyChecked"
      @update:visible="
        (val) => {
          dialogCompanySelectVisible = val;
        }
      "
    ></d-company-contact-select>

    <dialog-stock-select
      v-if="StkStockCheckVisible"
      slot="znl-dialog"
      :visible="StkStockCheckVisible"
      @close="
        () => {
          StkStockCheckVisible = false;
        }
      "
      @confirm="setGridData"
    ></dialog-stock-select>
  </div>
</template>
<script>
import { mixin as znlMethodsMixin } from "@c_common/znlGrid/scripts/znlMethods";
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import { mixin as moveToMixin } from "~assets/scripts/directives/moveTo";
import { renderRequiredColumn } from "~assets/scripts/methods/common";

import dCompanyContactSelect from "@c_modules/Crm/DCompanyContactSelect";
import autocomplete from "@c_common/znlGrid/autocomplete";
import pasteDataAcquisition from "@c_common/znlGrid/commonComponents/pasteDataAcquisition";
import ZnlPrint from "@c_common/znlGrid/commonComponents/printTemplates";
import DialogStockSelect from "@c_modules/Stk/DialogStockSelect";
import StockQuery from "@c_modules/Control/StockQuery";
import ItemStockLinkVue from "../Taobao/ItemStockLink.vue";

const CONFIG = {
  PK: "StkOutLineID",
  configURL: "StkOutLine/GetConfig",
  configJsonURL: "StkOut/GetConfig",
  StkOutSaveURL: "StkOutAdd/Add",
  StkOutSearchById: "StkOutAdd/Search",
  StkOutSearchByLineguid: "StkOutAdd/GetInlineByStkOutGUIDS",
  GetStkReserveGotoStkOut: "StkOutAdd/GetStkReserveGotoStkOut",
  GetStkVMIByGUIDS: "StockVMI/GetStkVMIByGUIDS", // 供应商库存供应商库存
  bomLineGotoStkOutURL: "BomDetailLine/BomLineGotoStkOut",
  GetPrice: "StkOutAdd/GetPrice", // 获取上次的价格
  SalesGotoStkOut: "SalesOrder/GotoStkOut",
  GenerateStkOutBySOLineGuids: "SalesOrderLine/GenerateStkOutBySOLineGuids", //生成发货单信息
  SalesLineGotoStkOut: "SalesOrderLine/SalesLineGotoStkOut",
  SavePageParamURL: "PageParam/Save", // 保存页面参数配置
  GetStklistURL: "StkOutAdd/GetInvQtyAndSalePrice", // 获取我的库存
  GetLinkedStockList: "TaobaoOrder/GetLinkedStockList",
  GetCompanySetupUrl: "CompanyParamSetup/GetValue", //获取公司配置
};

export default {
  name: "StkOutEdit",
  config: CONFIG,
  mixins: [
    znlMethodsMixin,
    moveToMixin,
    getCommonDataMixin,
    renderRequiredColumn,
  ],
  components: {
    // VCompanySelect,
    dCompanyContactSelect,
    autocomplete,
    pasteDataAcquisition,
    ZnlPrint,
    DialogStockSelect,
    StockQuery,
  },
  data() {
    return {
      // customizeColumns: [],
      hasOperationColumn: {
        StockQuery: false,
        StockVMIMini: false,
        PastInquire: false,
      },
      selectOptionsAddress: [],
      clientSelectLoading: false,
      dialogLoading: true,
      IsUseReserveRemark: false, //是否使用备货库存备注
      IsContinuousRecord: false,
      isBulkAddShow: false,
      StkStockCheckVisible: false,
      autocompleteShow: false,
      dialogCompanySelectVisible: false,
      defaultUnit: "",
      InTypes: "", // 1:客户，2：供应商，3:两者都是的公司
      StkOut: {
        IsAdd: true, // 是否新增
        BillNo: "", // 销售单号
        ShipTime: new Date().toLocaleDateString(), // 出货日期
        ShipUserID: this.$store.state.accountInfo.UserID, // 销售员ID
        Shipper: this.$store.state.accountInfo.UserName, // 销售员
        Amount: null, // 应收金额
        CustomerGUID: "", // 客户guid
        CustomerName: "", // 客户名称
        ReceiptType: null, // 收款方式
        ReceivedAmount: null, // 实收金额
        CustomerBankNo: "", // 收款帐户
        Address: "", // 收货地址
        ExpectReceiptDate: null, // 预计收款日期
        GrossProfit: null, // 利润总额
        DiscountAmount: null, // 折扣金额
        Contact: "", // 联系人
        Telephone: "", // 联系电话
        Mobile: "", // 手机号码
        CustomerOrderNo: "", // 客户订单号
        TaxRate: null, // 税点
        OtherFeeAmount: null, // 其它费用
        TransportCompany: "", // 快递公司
        ShippingNo: "", // 快递单号
        ExpressAccount: "", // 快递账号
        CurrencyCode: "", // 币种
        ExchangeRate: "1", // 汇率
        Remark: "", // 销售备注
        LineList: [], // 出库单详情
        IsAllowOut: true, // 是否允许出库
        IsAudited: false,
        CBGUID: "", // 收款账号,
        StkOutGUID: "", // 发货单ID
        StkOutID: 0,
        IsTakeOutPrint: false, // 是否带出上次价格
        Status: 1,
        OrderFrom: null, // 订单来源
        NewCustomerLabel: null, // 当为新客户时，默认为该客户添加的标签
      },
      // StkOutDiscountRate: '', // 折扣率
      StkOutReceiveAmount: 0, // 应收金额
      StkOutAmount: 0, // 发货金额
      IsStkOut: false, // 是否允许负库存出库
      IsCustomerNameRequired: false, // 公司名称是否必填
      CustomerNameTitle: "客户名称：",
      TransportCompanyOptions: [], // 快递公司
      resourceList: [],
      systemConfig: {
        hasFinance: false,
      },
      paramTax: "",
      paramQtyType: "",
      paramQtyUnit: "",
      ParamJosn: [],
      ParamJosnLine: [],
      disabledInput: true, // 禁用采购单
      IsManual: false, // 手动
      isAllow: true, // 是否允许选择手动框
      isPopup: false,
      columns: [],
      ondblclic: [],
      itemSource: [],
      itemQty: [],
      editRowIndex: -1,
      fieldsFilter: {},
      columnColors: [],
      rowColors: [],
      summaryColumns: [],
      setExcelColumns: [],
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      users: [],
      role: "1",
      showAddRowButton: true,
      showAddRowRightMenu: true,
      showDeleteRowButton: true,
      isVisibled: false,
      fromSalesOrderOpen: false,
      showClickBtn: true,
      salesOrderLineListOrigin: [],
      loading: false,
      refreshChecklist: false, // 是否刷新库存选择页面
      checkQty: false,
      isReadonly: false, // 控制输入框是否只读
      fromBtns: [],
      IsOutQty: true,
      IsExchangeRateRMB: true,
      startTime: 0,
      endTime: 0,
      restaurants: [],
      autocompleteSearchData: "",
      CompanyName: "",
      rowID: 0,
      SelectSearchModel: {
        CompanyName: "",
        Address: "",
        ContactName: "",
        Telephone: "",
        CompanyTypeStr: "",
      },
      IsShowPage: true,
      IsPrint: true, // 保存后继续打印
      // visibled: this.$store.state.visibled,
      IsstkOutGUIDLine: false, // 是否是销售退货
      isDisabled: false,
      // 打印模块参数
      printLoading: false,
      znlPrintVisible: false,
      GCode: "StkOut",
      printInfo: {
        isGetDefaultParam: true, // 是否已经从打印模板获取过默认值
        title: 0, // 公司抬头
        template: "", // 打印模板
      }, // end 打印模块参数
      mustFillFieldList: [],
      bankAccountNameList: [],
      dialogbox: {
        height: "640px",
        width: "1000px",
      },
      //公司配置
      CompanySetup: {
        EnableNotUpperCase: false, //单据的型号、品牌﹑封装、年份默认为非大写格式
      },
    };
  },
  props: {
    editType: String,
    title: {
      type: String,
      default: () => {
        return "编辑发货单";
      },
    },
    editvisible: {
      type: Boolean,
      defaule: false,
    },
    searchModel: {
      type: Object,
      default: () => {
        return {};
      },
    },
    height: {
      type: String,
      default: () => {
        return "99%";
      },
    },
    stockLine: Array, // 库存对象
    stkOutGUIDLine: Array, // 出库明细
    stkOutGUID: String,
    refershReserve: Boolean, // 从库存备货页面进行出库
    ReserveGuid: String, // 我的备货进行出库

    isRefreshVMI: Boolean, // 供应商库存
    VMGUIDS: Array, // 供应商库存
    BDLineGuids: Array, // 库存对象GUIDS
    autoAddNewRow: {
      // 是否在明细自动增加一行
      type: Boolean,
      default: () => {
        return true;
      },
    },
  },
  watch: {},
  methods: {
    // 初始化
    onInit: async function () {
      this.dialogLoading = true;
      let mainConfigs =
        (await this.isSaveStorage("StkOut", CONFIG.configJsonURL)) || {};
      let config =
        (await this.isSaveStorage("StkOutLine", CONFIG.configURL)) || {};
      this.resourceList = (mainConfigs.ResourceList || []).map(
        (item) => item.Code
      );
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs);
      // 获取参数配置
      this.ParamJosn = mainConfigs.ParamJosn;
      // 获取订单详情参数配置
      this.ParamJosnLine = config.ParamJosn;
      this.paramConfiguration();
      let isvisibled = this.$store.state.visibled;
      if (isvisibled) {
        this.isVisibled = isvisibled;
      }
      // 初始化列下拉框、列权限配置
      await this.initPermission(columns);

      this.defaultConfig = config;
      columns = this.renderRequiredColumn(columns);
      this.columns = columns;
      this.role = config.Role;
      this.pageSize = config.PageSize;
      this.fromSalesOrderOpen = false;

      // 默认税点
      let value = this.getCompanySetupValue("DefaultTaxRate");
      if (value) {
        this.StkOut.TaxRate = value;
      }

      // 判断、加载各路神仙传过来的数据
      if (this.$store.state.paramOrder) {
        // 编辑
        if (this.$store.state.paramOrder.stkOutGUID) {
          let stkoutguid = this.$store.state.paramOrder.stkOutGUID;
          this.StkOut.IsAdd = false;
          this.$store.commit("setParamOrder", {});
          await this.LoadEditData(stkoutguid);
        }
        // 从发货单主单退货
        if (this.$store.state.paramOrder.stkOutReturnInfo) {
          this.IsstkOutGUIDLine = true;
          let info = this.$store.state.paramOrder.stkOutReturnInfo;
          this.$store.commit("setParamOrder", {});
          this.LoadReturnInfo(info);
        }

        // 通过明细id打开，新增
        if (this.$store.state.paramOrder.stkOutGUIDLine) {
          this.IsstkOutGUIDLine = true;
          let info = this.$store.state.paramOrder.stkOutGUIDLine;
          let isreturn = false;
          if (this.$store.state.paramOrder.IsReturn) {
            isreturn = this.$store.state.paramOrder.IsReturn;
          }
          this.$store.commit("setParamOrder", {});
          this.LoadLineAddData(info, isreturn);
        }

        if (this.$store.state.paramOrder.stockLine) {
          let info = this.$store.state.paramOrder.stockLine;
          let IsOutQty = this.$store.state.paramOrder.IsOutQty;
          this.IsOutQty = IsOutQty;
          this.$store.commit("setParamOrder", {});
          this.LoadListByStock(info, false);
        }
        if (this.$store.state.paramOrder.VMGUIDS) {
          let info = this.$store.state.paramOrder.VMGUIDS;
          this.$store.commit("setParamOrder", {});
          this.LoadStkVMIGUID(info, false);
        }
        if (this.$store.state.paramOrder.RFQ_InquireModel) {
          let info = this.$store.state.paramOrder.RFQ_InquireModel;
          this.$store.commit("setParamOrder", {});
          this.InquireModel(info);
        }
        if (this.$store.state.paramOrder.InquireModel) {
          let info = this.$store.state.paramOrder.InquireModel;
          this.$store.commit("setParamOrder", {});
          this.InquireModel(info);
        }

        if (this.$store.state.paramOrder.setParamShared) {
          let info = this.$store.state.paramOrder.setParamShared;
          this.$store.commit("setParamOrder", {});
          this.InquireModel(info);
        }
        if (this.$store.state.paramOrder.SalesOrderInfo) {
          let info = this.$store.state.paramOrder.SalesOrderInfo;
          this.$store.commit("setParamOrder", {});
          this.isPopup = false;
          await this.LoadSaleData(info);
          this.showAddRowButton = false;
          this.showAddRowRightMenu = false;
          this.showDeleteRowButton = false;
          this.fromSalesOrderOpen = true;
          this.isAllow = false; // 编辑禁用选择
        }
        //从销售明细发货
        if (this.$store.state.paramOrder.SalesOrderLineInfo) {
          let info = this.$store.state.paramOrder.SalesOrderLineInfo;
          this.$store.commit("setParamOrder", {});
          this.isPopup = false;
          await this.LoadSaleLineData(info);
          this.showAddRowButton = false;
          this.showAddRowRightMenu = false;
          this.showDeleteRowButton = false;
          this.fromSalesOrderOpen = true;
          this.isAllow = false; // 编辑禁用选择
        }
        // 销售备货 出库
        if (this.$store.state.paramOrder.ReverseGuid) {
          let reverseGuid = this.$store.state.paramOrder.ReverseGuid;
          this.$store.commit("setParamOrder", {});
          await this.LoadReserveLinePL(reverseGuid);
        }
        // 按需求单生成发货单
        if (
          this.$store.state.paramOrder &&
          this.$store.state.paramOrder.bomInfo
        ) {
          let info = this.$store.state.paramOrder.bomInfo;
          await this.LoadBomLine(info, false);
          this.$store.commit("setParamOrder", {});
        }
        if (this.$store.state.paramOrder.stkInLines) {
          let stkInLines = this.$store.state.paramOrder.stkInLines;
          this.$store.commit("setParamOrder", {});
          this.$nextTick(function () {
            _.each(stkInLines, (items) => {
              items.BuyPrice = items.Price;
              items.Price = items.SalesPrice;
              items.PriceInTax = items.SalesPrice;
              let amount = this.toFloat(
                this.ConvertNumber(items.PriceInTax) *
                  this.ConvertNumber(items.Qty),
                2
              );
              items.Amount = amount;
              items.Status = 1;
            });
            this.StkOut.CustomerName = stkInLines[0].SupplierName;
            this.itemSource = stkInLines;
            this.$nextTick(function () {
              this.onBindData();
              this.CalcPay(true);
              this.CalcStkOutAmoutAndGrossProfit(true);
            });
          });
        }

        // 从淘宝订单生成发货单
        if (this.$store.state.paramOrder.taobaoOrder) {
          let order = this.$store.state.paramOrder.taobaoOrder;
          this.$store.commit("setParamOrder", {});
          this.loadByTaobao(order, false);
        }
      }

      this.fromBtns = [
        {
          name: "新增一行",
          iconName: "icon-add_btn_ic",
          isShow: !this.isDisabled && this.isAllow,
          click: () => {
            this.addNewRow();
          },
        },
        {
          name: "删除选择行",
          iconName: "icon-delete_btn_ic",
          isShow: !this.isDisabled,
          click: () => {
            _.each(this.$refs.flexGrid.getSelectedRows(), (data) => {
              _.each(this.itemSource, (item, i) => {
                if (_.isEqual(item, data)) {
                  this.itemSource.splice(i, 1);
                  return;
                }
              });
            });
            let Amount = 0;
            _.each(this.itemSource, (item, i) => {
              if (item.Amount) Amount += Number(item.Amount);
            });
            this.StkOut.Amount = Amount;
          },
        },
        {
          name: "导入数据",
          iconName: "icon-add_btn_ic",
          isShow: !this.isDisabled && this.isAllow,
          click: () => {
            this.isBulkAddShow = !this.isBulkAddShow;
            this.setExcelColumns = _.filter(this.columns, (item) => {
              return item.isReadOnly === false;
            });
          },
        },
        {
          name: "我的库存",
          iconName: "icon-save_btn_ic",
          isShow: !this.isDisabled && this.isAllow,
          click: () => {
            this.StkStockCheckVisible = true;
          },
        },
      ];
      this.refreshColumnsPermission();
      this.$nextTick(() => {
        this.dialogLoading = false;
      });
      this.loadMustFillFields();
      this.setNewRowDefaultConfig();
      await this.initCompanySetup();
    },
    //初始化公司配置
    async initCompanySetup() {
      this.$post(
        CONFIG.GetCompanySetupUrl,
        { SCode: "EnableNotUpperCase" },
        (data, logic) => {
          if (logic.code === 200) {
            data = data.toString().toUpperCase();
            this.CompanySetup.EnableNotUpperCase =
              data === "1" || data === "Y" || data === "YES" || data === "TRUE";
          }
        }
      );
    },

    async initPermission(columns) {
      let noSeeProfit = this.hasSpecialRes("NoSeeProfit");
      let noSeeSupplier = this.hasSpecialRes("NoSeeSupplier");
      let invTypeList = await this.selectStorageType;
      let QualityDropDown = await this.selectOptionsQuality;
      let UserAllowUsers = await this.selectOptionsAllUserURL;
      let QtyUnitDropDown = await this.selectOptionsQtyUnit;
      let notModifySalesPrice = this.hasRes("Not_Allow_Modify_Sales_Price");
      let notModifyStkOutQty = this.hasRes("Not_Allow_Modify_StkOut_Qty");
      let viewBuyPrice = this.getSpecialResourceByCode("ViewBuyPrice");

      // 循环列
      _.each(columns, (item, index) => {
        // 不看利润特殊权限
        if (noSeeProfit || !viewBuyPrice) {
          switch (item.binding) {
            case "GrossProfit": // 利润
            case "ProfitMargins": // 利润率
            case "BuyPrice": // 采购价
            case "PurchaseAmount": // 采购额
              _.extend(item, { isReadOnly: true, hasRole: false });
              break;
          }
        }

        // 下拉列表和隐藏列
        switch (item.binding) {
          case "StatusName": // 隐藏状态列和退货量列
          case "ReturnQty":
            item.hasRole = false;
            item.visible = false;
            break;

          case "BuyerName": // 采购员
            _.extend(item, { dataMap: UserAllowUsers, showDropDown: true });
            break;

          case "Unit": // 单位
            _.extend(item, { dataMap: QtyUnitDropDown, showDropDown: true });
            break;

          case "InvType": // 库存类型
            _.extend(item, { dataMap: invTypeList, showDropDown: true });
            break;

          case "Quality": // 品质
            _.extend(item, { dataMap: QualityDropDown, showDropDown: true });
            break;

          case "SupplierInfo": // 不看供应商信息
            if (noSeeSupplier) {
              item.visible = false;
            }
            break;
          case "PriceInTax": // 不修改销售价(子账号)
          case "Price":
            if (notModifySalesPrice && !this.StkOut.IsAdd) {
              item.isReadOnly = notModifySalesPrice;
            }
            break;
          case "Qty":
            if (notModifyStkOutQty && !this.StkOut.IsAdd) {
              item.isReadOnly = notModifyStkOutQty;
            }
            break;
        }
      });
    },

    refreshColumnsPermission() {
      let notModifySalesPrice = this.hasRes("Not_Allow_Modify_Sales_Price");
      let notModifyStkOutQty = this.hasRes("Not_Allow_Modify_StkOut_Qty");

      // 循环列
      _.each(this.columns, (item, index) => {
        switch (item.binding) {
          case "PriceInTax": // 不修改销售价(子账号)
          case "Price":
            if (notModifySalesPrice && !this.StkOut.IsAdd) {
              item.isReadOnly = notModifySalesPrice;
            }
            break;
          case "Qty":
            if (notModifyStkOutQty && !this.StkOut.IsAdd) {
              item.isReadOnly = notModifyStkOutQty;
            }
            break;
        }
      });
    },

    loadMustFillFields() {
      // 必填字段
      let mustFillFieldList = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn && paramjosn.ParamCode === "MustFillField") {
          return paramjosn.ParamValue;
        }
      });
      if (
        this.hasValue(mustFillFieldList) &&
        this.hasValue(mustFillFieldList.ParamValue)
      ) {
        this.mustFillFieldList = mustFillFieldList.ParamValue.split(",");
      }
    },
    // 多页签 双击事件回调
    async ondblclick(row, typeName) {
      // console.log(row, typeName, this.itemSource, this.columns)
      if (_.isUndefined(row)) {
        return;
      }
      let rows = [row];

      if (typeName === "StockQuery") {
        // 我的库存
        this.LoadListByStock(rows);
      } else if (typeName === "StockVMIMini") {
        // 供应商库存
        this.LoadStkVMI(rows);
      } else if (typeName === "StkOutByModel") {
        // 发货记录
        this.loadStkOutLine(rows);
      } else if (typeName === "HisOfferPriceByModel") {
        // 报价记录
        this.loadQuotedPriceLine(rows);
      }
    },
    setClientSelectLoading(val) {
      this.dialogLoading = val;
      this.IsShowPage = val;
    },
    // 打印参数，选中的打印模板
    setPrintParam(printInfo) {
      this.printInfo.title = printInfo.title;
      this.printInfo.template = printInfo.template;
    },
    confirmPrint(data) {
      this.onPrint();
    },
    // 打印模板
    onPrint() {
      let tableName = this.printInfo.template; // 模板ID
      let OutGuid = this.StkOut.StkOutGUID; // 销售单ID
      if (!this.hasValue(tableName)) {
        this.$message({ message: "先选择打印模板", type: "error" });
        this.znlPrintVisible = true;
        return false;
      } else if (OutGuid === "" || OutGuid === null || OutGuid === undefined) {
        this.$message({ message: "先保存后才能打印", type: "error" });
        return false;
      } else {
        let printParm = {
          TableName: tableName,
          Guid: OutGuid,
          SubCompanyId: this.printInfo.title,
        };
        console.log(this.printInfo, 'edit print');

        this.printStkOut(printParm, () => {});
        return true;
      }
    },
    setGridData(rows = null) {
      let list = [];

      _.each(rows, (item) => {
        if (!this.hasValue(item.InDate)) item.InDate = item.StkInTime;
        let invQty = item.UsableQty;
        item.Qty = invQty;
        let price = item.SalesPrice;
        if (!this.hasValue(item.SalesPrice)) {
          price = item.Price;
        }
        item.RowGuid = this.rowID;
        this.rowID++;
        item.InvType = !this.hasValue(item.InvType)
          ? this.paramQtyType
          : item.InvType; // 库存类型
        item.Unit = !this.hasValue(item.Unit) ? this.paramQtyUnit : item.Unit; // 单位
        item.Price = this.ConvertNumber(price);

        item.PriceInTax = this.CalcPriceInTax(this.ConvertNumber(price)); // 含税价
        item.Amount = this.CalcAmout(
          this.ConvertNumber(invQty),
          this.ConvertNumber(price)
        );
        item.GrossProfit = this.CalcGrossProfit(
          this.ConvertNumber(price),
          this.ConvertNumber(item.BuyPrice),
          this.ConvertNumber(invQty),
          this.ConvertNumber(item.shareFee),
          this.ConvertNumber(item.Discount)
        ); // 毛利润
        item.PurchaseAmount = this.CalcAmout(invQty, item.BuyPrice);

        // 客戶名称
        if (item.CustomerName && !this.hasValue(this.StkOut.CustomerName)) {
          this.StkOut.CustomerName = item.CustomerName;
        }
        item.StkOutLineGUID = null;
        let dataFrom = item["__dataFrom"];
        if (dataFrom && dataFrom == "stock-query") {
          item.Remark3 = item.Remark2;
          item.Remark2 = item.Remark1;
          item.Remark = item.Remark;
        }

        list.push(item);
      });
      if (this.itemSource && this.itemSource.length > 0) {
        let info = this.itemSource[this.itemSource.length - 1];
        if (
          !this.hasValue(info.Model) &&
          !this.hasValue(info.Qty) &&
          !this.hasValue(info.SalesModel)
        ) {
          this.itemSource.pop();
        }
      }
      this.itemSource = this.itemSource.concat(list);
      this.CalcStkOutAmoutAndGrossProfit(false);
    },
    addExcelData(data) {
      let index = 0;
      if (this.itemSource && this.itemSource.length > 0) {
        index = this.itemSource.length - 1;
        let info = this.itemSource[index];
        if (!this.hasValue(info.Model) && !this.hasValue(info.Qty)) {
          this.itemSource.pop();
          index--;
        }
      }
      _.each(data, (item) => {
        this.getValue(item);
      });

      _.each(data, (item) => {
        index++;
        item.ID = index;
        this.itemSource.push(item);
      });
      this.isBulkAddShow = false;
    },
    getValue(item) {
      // 如果单位为空 则默认参数配置的默认单位
      if (!this.hasValue(item.Unit)) {
        item.Unit = this.paramQtyUnit;
      }
      // 如果库存类型为空则默认参数配置的默认库存类型
      if (!this.hasValue(item.InvType)) {
        item.InvType = this.paramQtyType;
      }
      // 计算未税价和含税价和金额
      if (this.hasValue(item.Price)) {
        // 计算含税
        let _priceIntax = this.CalcPriceInTax(item.Price);
        item.PriceInTax = _priceIntax === 0 ? null : _priceIntax;
      } else if (this.hasValue(item.PriceInTax)) {
        // 含税算未税
        item.Price = this.CalcPrice(item.PriceInTax);
      }
      item.GrossProfit = this.CalcGrossProfit(
        item.Price,
        item.BuyPrice,
        item.Qty,
        item.ShareFee,
        item.Discount
      );
      item.Amount = this.CalcAmout(item.Qty, item.PriceInTax).toFixed(2);
      this.CalcPay(false);
      this.CalcStkOutAmoutAndGrossProfit(false);
    },
    // 获取参数配置
    paramConfiguration() {
      // 单据保存后立即打印 0是 1否
      let paramIsAutoPrint = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn && paramjosn.ParamCode === "IsAutoPrint") {
          return paramjosn.ParamValue;
        }
      });
      if (paramIsAutoPrint && paramIsAutoPrint.ParamValue === "1") {
        this.IsPrint = true;
      } else {
        this.IsPrint = false;
      }
      // 是否使用备货库存备注
      let paramIsUseReserveRemark = _.find(
        this.ParamJosn,
        function (paramjosn) {
          if (paramjosn && paramjosn.ParamCode === "IsUseReserveRemark") {
            return paramjosn.ParamValue;
          }
        }
      );
      if (
        paramIsUseReserveRemark &&
        paramIsUseReserveRemark.ParamValue === "1"
      ) {
        this.IsUseReserveRemark = true;
      } else {
        this.IsUseReserveRemark = false;
      }
      // 连续录单模式
      let paramIsContinuous = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn && paramjosn.ParamCode === "IsContinuousRecord") {
          return paramjosn.ParamValue;
        }
      });
      if (paramIsContinuous && paramIsContinuous.ParamValue === "1") {
        this.IsContinuousRecord = true;
      } else {
        this.IsContinuousRecord = false;
      }

      // 默认单位
      let paramQtyUnit = _.find(this.ParamJosnLine, function (paramjosn) {
        if (paramjosn && paramjosn.ParamCode === "QtyUnit") {
          return paramjosn.ParamValue;
        }
      });
      if (paramQtyUnit && paramQtyUnit.ParamValue) {
        this.paramQtyUnit = paramQtyUnit.ParamValue;
      }

      let defaultUnit = this.getCompanySetupValue("DefaultUnit");
      if (defaultUnit) {
        this.paramQtyUnit = defaultUnit;
      }

      // 默认库存类型
      let paramQtyType = _.find(this.ParamJosnLine, function (paramjosn) {
        if (paramjosn && paramjosn.ParamCode === "QtyType") {
          return paramjosn.ParamValue;
        }
      });
      if (paramQtyType && paramQtyType.ParamValue) {
        this.paramQtyType = paramQtyType.ParamValue;
      }

      // 默认币种
      let defaultCurrencyCode = this.getCompanySetupValue(
        "DefaultSalesCurrencyCode"
      );
      if (defaultCurrencyCode) {
        this.StkOut.CurrencyCode = defaultCurrencyCode;
        let exchangeObj = _.find(
          this.selectOptionsCurrencyCode,
          (m) => m.key === defaultCurrencyCode
        );
        if (exchangeObj && exchangeObj.ExchangeRate) {
          this.StkOut.ExchangeRate = exchangeObj.ExchangeRate;
        }
      }

      // 默认税率
      let defaultTaxRate = this.getCompanySetupValue("DefaultTaxRate");
      if (defaultTaxRate) {
        this.StkOut.TaxRate = defaultTaxRate;
      }

      let paramIsStkOut = _.find(this.ParamJosn, function (paramjosn) {
        // 是否允许负库存出库
        if (paramjosn && paramjosn.ParamCode === "IsStkOut") {
          return paramjosn.ParamValue;
        }
      });
      if (this.hasValue(paramIsStkOut) && paramIsStkOut.ParamValue === "0") {
        this.IsStkOut = false;
      } else {
        this.IsStkOut = true;
      }

      // 是否带出上次价格
      let paramIsTakeOutPrint = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn && paramjosn.ParamCode === "IsTakeOutPrint") {
          return paramjosn.ParamValue;
        }
      });
      if (
        this.hasValue(paramIsTakeOutPrint) &&
        paramIsTakeOutPrint.ParamValue === "0"
      ) {
        this.StkOut.IsTakeOutPrint = false;
      } else {
        this.StkOut.IsTakeOutPrint = true;
      }

      // 公司名称是否必填
      let value = this.getCompanySetupValue("MustCompanyName");
      if (value === "1") {
        this.CustomerNameTitle = "*客户名称：";
        this.IsCustomerNameRequired = true;
      }

      // 默认单位
      this.defaultUnit = this.getCompanySetupValue("DefaultUnit") || "";
      //   let paramIsCustomerName = _.find(this.ParamJosn, function (paramjosn) {
      //     if (paramjosn && paramjosn.ParamCode === 'IsCustomerNameRequired') {
      //       return paramjosn.ParamValue
      //     }
      //   })
      //   if (this.hasValue(paramIsCustomerName) && paramIsCustomerName.ParamValue === '0') { this.IsCustomerNameRequired = false } else { this.IsCustomerNameRequired = true }
      // // 验证是否先做销售单
      // let ExistSalesOrder = _.find(this.ParamJosn, function (paramjosn) {
      //   if (paramjosn && paramjosn.ParamCode === 'ExistSalesOrder') {
      //     return paramjosn.ParamValue
      //   }
      // })
      // if (this.hasValue(ExistSalesOrder) && ExistSalesOrder.ParamValue === '0') { this.StkOut.ExistSalesOrder = false } else { this.StkOut.ExistSalesOrder = true }

      // 如果设置默认值，加载时计算
      // this.CalcTaxRate(this.StkOut.TaxRate)

      // 打印单据模板
      let paramPrintTemplate = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn && paramjosn.ParamCode === "PrintTemplate") {
          return paramjosn.ParamValue;
        }
      });
      if (
        this.hasValue(paramPrintTemplate) &&
        this.hasValue(paramPrintTemplate.ParamValue)
      ) {
        this.printInfo.template = paramPrintTemplate.ParamValue || "";
      }
      // 打印单据模板的抬头
      let paramPrintTitle = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn && paramjosn.ParamCode === "PrintTitle") {
          return paramjosn.ParamValue;
        }
      });
      if (
        this.hasValue(paramPrintTitle) &&
        this.hasValue(paramPrintTitle.ParamValue)
      ) {
        this.printInfo.title = paramPrintTitle.ParamValue;
      }
    },
    // 绑定数据
    onBindData() {
      this.ParamJonsValue();
      this.StkOut.LineList = this.itemSource;
      // debugger
      // 如果单据是已审核状态，则部分列不允许编辑
      if (this.StkOut && this.StkOut.Status === 3) {
        let readonlyCols = "SalesModel,Model,Qty".split(",");
        _.each(this.columns, (col) => {
          if (_.some(readonlyCols, (code) => code === col.binding)) {
            col.isReadOnly = true;
            col.className = "znl-is-read-only";
          }
        });
      }
      this.StkOutReceiveAmount =
        this.StkOut.Amount - this.StkOut.DiscountAmount;
      this.$emit("page-loading", false);
    },
    // 默认参数设置赋值
    ParamJonsValue() {
      _.each(this.itemSource, (item, i) => {
        if (
          this.itemSource[i].StkOutLineGUID === undefined ||
          this.itemSource[i].StkOutLineGUID === ""
        ) {
          if (!this.hasValue(this.itemSource[i].Unit)) {
            this.itemSource[i].Unit = this.paramQtyUnit;
          }
          if (!this.hasValue(this.itemSource[i].InvType)) {
            this.itemSource[i].InvType = this.paramQtyType;
          }
        }
      });
    },
    // 客户名称联想
    querySearch(queryString, cb) {
      if (new Date() - this.startTime > 1000) {
        this.$post(
          "CompanyContacts/SearchCompanyContacts",
          {
            FieldWhereString: JSON.stringify({
              CompanyName: queryString,
            }),
            PageIndex: 1,
            PageSize: 20,
            CompanyTypes: "1,3",
          },
          (data) => {
            this.restaurants = data.ResultList;
            let restaurants = this.restaurants;
            let results = queryString
              ? restaurants.filter(this.createFilter(queryString))
              : restaurants;
            // 调用 callback 返回建议列表的数据
            _.each(results, (item) => {
              item.value = item.CompanyName;
            });
            cb(results);
            _.delay(() => {
              this.$refs.znlAutocomplete.$el
                .querySelector(".el-input__inner")
                .focus();
            }, 100);
          }
        );
      }
      this.startTime = new Date();
    },
    createFilter(queryString) {
      return (restaurant) => {
        return restaurant.CompanyName.indexOf(queryString.toLowerCase()) === 0;
      };
    },
    suggestionsSelect(item) {
      // console.log(item)
      this.companyChecked([item]);
    },
    onIconClick() {
      this.dialogCompanySelectVisible = true;
      this.SelectSearchModel.CompanyName = this.StkOut.CustomerName;
      this.CompanyName = this.StkOut.CustomerName;
    },
    hasSpecialRes(code) {
      return _.some(
        this.selectOptionsSpecialResource,
        (page) => page.Code === code
      );
    },
    receiptTypeChange(val) {
      this.StkOut.ReceiptType = val === undefined ? "" : val.key;
      if (this.StkOut.ReceiptType === "现金" && this.StkOut.IsAdd) {
        this.$message({
          message: "收款方式:【现金】请输入实收金额.",
          type: "warning",
        });
        this.StkOut.ReceivedAmount =
          this.toFloat(this.StkOut.Amount, 2) -
          this.toFloat(this.StkOut.DiscountAmount, 2);
      } else if (
        this.StkOut.ReceiptType.indexOf("月结") > -1 ||
        this.StkOut.ReceiptType.indexOf("挂欠") > -1
      ) {
        this.$message({ message: "请选择预计收款日期", type: "warning" });
        this.StkOut.ReceivedAmount = null;
      } else {
        this.StkOut.ReceivedAmount = null;
      }
    },
    CurrencyCodeChange(val) {
      // 币种改变选项事件
      let itemVal = val ? val.key : this.StkOut.CurrencyCode; // 选中的值
      if (!this.hasValue(itemVal)) {
        itemVal = "RMB";
      }
      if (itemVal === "RMB") {
        this.IsExchangeRateRMB = true;
      } else {
        this.IsExchangeRateRMB = false;
      }
      var info = _.find(this.selectOptionsCurrencyCode, function (item) {
        return item.key === itemVal;
      });
      this.StkOut.ExchangeRate = info.ExchangeRate;
    },
    // 验证 GUID加载实体税点是否为空 如果为空则默认参数配置， 从新计算
    ResTaxRate() {
      if (!this.hasValue(this.StkOut.TaxRate)) {
        this.StkOut.TaxRate = this.paramTax;
      }
      this.CalcTaxRate();
    },
    // 保存订单
    Save(isOut) {
      this.StkOut.IsAllowOut = isOut; // 是否允许出库
      this.loading = true;
      this.isDisabled = true;
      return this.$post(CONFIG.StkOutSaveURL, this.StkOut, (data, logic) => {
        if (logic.code !== 200) {
          this.$message({ message: logic.msg, type: "error" });
        } else {
          this.$message({ message: "保存成功", type: "success" });
          this.StkOut = {};
          this.StkOut = data;
          this.itemSource = data.LineList;
          this.StkOut.IsAdd = false;
          this.$emit("save-success");
          if (this.IsPrint) {
            this.onPrint();
          }
          if (isOut && !this.IsContinuousRecord) {
            this.$emit("close");
          } else if (this.IsContinuousRecord) {
            this.onResetStkout(); // 重置
          }
        }
      }).finally(() => {
        this.loading = false;
        this.isDisabled = false;
      });
    },
    // 保存订单
    async stkoutSave() {
      if (this.isDisabled) {
        return;
      }
      try {
        this.isDisabled = true;
        if (!this.checkData() || !this.checkLength()) {
          return;
        }
        let flag = await this.checkLineSalesPriceAndInvQty();
        if (!flag) {
          return;
        }
        this.Save(false);
      } finally {
        this.isDisabled = false;
      }
    },
    // 保存并出库
    async stkoutSaveAndOut() {
      if (this.isDisabled) {
        return;
      }
      try {
        this.isDisabled = true;
        if (!this.checkData() || !this.checkLength()) {
          return;
        }
        let flag = await this.checkLineSalesPriceAndInvQty();
        if (!flag) {
          return;
        }
        this.Save(true);
      } finally {
        this.isDisabled = false;
      }
    },
    checkData() {
      let msg = "";
      if (this.hasValue(this.StkOut.ReceivedAmount)) {
        this.StkOut.ReceivedAmount = this.toFloat(
          this.StkOut.ReceivedAmount,
          2
        );
      }
      this.onSaleChange();
      if (this.$refs.flexGrid.hasErrForEdit > 0) {
        msg = "请修改填写错误的单元格内容后再保存";
      } else if (
        this.IsCustomerNameRequired &&
        !this.hasValue(this.StkOut.CustomerName)
      ) {
        this.$refs.znlAutocomplete.handleFocus();
        msg = "请填写客户名称";
      } else if (!this.hasValue(this.StkOut.ShipTime)) {
        this.$refs.ShipTime.handleFocus();
        msg = "请选择发货日期";
      } else if (!this.hasValue(this.StkOut.ShipUserID)) {
        this.$refs.ShipUserID.handleFocus();
        msg = "请选择发货员";
      } else if (
        this.StkOut.CurrencyCode === undefined ||
        this.StkOut.CurrencyCode === "" ||
        this.StkOut.CurrencyCode === 0
      ) {
        this.$refs.CurrencyCode.handleFocus();
        msg = "请选择币种";
      } else if (
        this.StkOut.DiscountAmount !== undefined &&
        !isFinite(this.StkOut.DiscountAmount)
      ) {
        this.$refs.DiscountAmount.handleFocus();
        msg = "折扣金额必须为数字或者小数";
      } else if (
        this.StkOut.OtherFeeAmount !== undefined &&
        !isFinite(this.StkOut.OtherFeeAmount)
      ) {
        this.$refs.OtherFeeAmount.handleFocus();
        msg = "其它费用必须为数字或者小数";
      } else if (
        this.StkOut.ExchangeRate !== undefined &&
        !isFinite(this.StkOut.ExchangeRate)
      ) {
        this.$refs.ExchangeRate.handleFocus();
        msg = "汇率必须为数字或者小数";
      } else if (
        !this.isReadonly &&
        this.systemConfig.hasFinance &&
        (this.StkOut.ReceivedAmount === "" ||
          this.StkOut.ReceivedAmount === 0) &&
        this.StkOut.ReceiptType === "现金"
      ) {
        this.$refs.ReceivedAmount.handleFocus();
        msg = "收款方式:【现金】请输入实收金额";
      } else if (
        !this.isReadonly &&
        this.hasValue(this.StkOut.ReceiptType) &&
        this.StkOut.ReceiptType.indexOf("月结") > -1 &&
        !this.hasValue(this.StkOut.ExpectReceiptDate)
      ) {
        msg = "收款方式:【月结】请输入预收款日期";
      } else if (
        !this.isReadonly &&
        this.systemConfig.hasFinance &&
        this.StkOut.ReceivedAmount &&
        this.StkOut.ReceivedAmount !== "" &&
        this.StkOut.ReceivedAmount !== 0 &&
        !this.hasValue(this.StkOut.CBGUID)
      ) {
        msg = "已填写实收额,请选择收款账号";
      } else if (
        !this.isReadonly &&
        this.systemConfig.hasFinance &&
        !this.hasValue(this.StkOut.ReceivedAmount) &&
        this.hasValue(this.StkOut.CBGUID)
      ) {
        msg = "已选择收款账号，请填写实收金额";
      } else {
        //删除为空的最后一行
        if (this.itemSource.length > 1) {
          var lastCol = this.itemSource.length - 1;
          var lastRow = this.itemSource[lastCol];
          if (
            !this.hasValue(lastRow["Model"]) &&
            !this.hasValue(lastRow["Qty"]) &&
            !this.hasValue(lastRow["Price"])
          )
            this.itemSource = this.itemSource.splice(0, lastCol);
        }
        let rows = this.itemSource;
        if (rows.length > 0) {
          let rowId = 0;
          _.find(rows, (item) => {
            rowId = rowId + 1;
            if (!this.hasValue(item["Model"])) {
              msg = "请填写发货型号";
            } else if (!isFinite(item["Qty"]) || !this.hasValue(item["Qty"])) {
              msg = "数量必须为数值型且不能为空";
            } else if (
              !this.hasValue(item["Price"]) ||
              !this.hasValue(item["PriceInTax"])
            ) {
              msg = "未税价必须为数值型且不能为空";
            } else if (
              !_.isUndefined(item["ShareFee"]) &&
              !isFinite(item["ShareFee"])
            ) {
              msg = "分摊费必须为数值型";
            } else if (
              !_.isUndefined(item["BuyPrice"]) &&
              !isFinite(item["BuyPrice"])
            ) {
              msg = "采购价必须为数值型";
            } else if (
              this.hasValue(item["CustomerPartNo"]) &&
              item["CustomerPartNo"].length > 50
            ) {
              msg = "客户料号不能超过50个字符";
            } else if (
              this.hasValue(item["ProductCategory"]) &&
              item["ProductCategory"].length > 50
            ) {
              msg = "产品类型不能超过50个字符";
            } else if (
              this.hasValue(item["SalesModel"]) &&
              item["SalesModel"].length > 200
            ) {
              msg = "销售型号不能超过200个字符";
            } else if (
              this.hasValue(item["Model"]) &&
              item["Model"].length > 200
            ) {
              msg = "发货型号不能超过200个字符";
            } else if (
              this.hasValue(item["Brand"]) &&
              item["Brand"].length > 50
            ) {
              msg = "品牌长度不能超过50个字符";
            } else if (
              this.hasValue(item["Packaging"]) &&
              item["Packaging"].length > 50
            ) {
              msg = "封装长度不能超过50个字符";
            } else if (
              this.hasValue(item["MakeYear"]) &&
              item["MakeYear"].length > 50
            ) {
              msg = "年份长度不能超过50个字符";
            } else if (this.hasValue(item["MPQ"]) && item["MPQ"].length > 50) {
              msg = "包装长度不能超过50个字符";
            } else if (
              this.hasValue(item["DeliveryDate"]) &&
              item["DeliveryDate"].length > 50
            ) {
              msg = "货期长度不能超过50个字符";
            } else if (
              this.hasValue(item["Demands"]) &&
              item["Demands"].length > 50
            ) {
              msg = "采购要求长度不能超过50个字符";
            } else if (
              this.hasValue(item["StorageName"]) &&
              item["StorageName"].length > 50
            ) {
              msg = "出库位置长度不能超过50个字符";
            } else if (
              this.hasValue(item["InvType"]) &&
              item["InvType"].length > 50
            ) {
              msg = "库存类型长度不能超过50个字符";
            } else if (
              this.hasValue(item["Remark"]) &&
              item["Remark"].length > 200
            ) {
              msg = "备注1长度不能超过200个字符";
            } else if (
              this.hasValue(item["Remark2"]) &&
              item["Remark2"].length > 200
            ) {
              msg = "备注2长度不能超过200个字符";
            }
            if (msg !== "") {
              msg = "单据明细第" + rowId + "行" + msg;
              return true;
            }
          });
          this.StkOut.LineList = rows;
        } else {
          msg = "请添加出库单详情";
        }
      }
      if (msg !== "") {
        this.$message({ message: msg, type: "warning" });
        return false;
      }
      return true;
    },
    checkLength() {
      let ret = true;
      if (
        this.hasValue(this.StkOut.CustomerName) &&
        this.StkOut.CustomerName.length > 100
      ) {
        ret = false;
        this.$refs.znlAutocomplete.handleFocus();
        this.$message({
          message: "客户名称长度不能超过100个字符",
          type: "warning",
        });
      } else if (
        this.hasValue(this.StkOut.Contact) &&
        this.StkOut.Contact.length > 50
      ) {
        ret = false;
        this.$refs.Contact.handleFocus();
        this.$message({
          message: "联系人长度不能超过50个字符",
          type: "warning",
        });
      } else if (
        this.hasValue(this.StkOut.Telephone) &&
        this.StkOut.Telephone.length > 50
      ) {
        ret = false;
        this.$message({
          message: "联系电话长度不能超过50个字符",
          type: "warning",
        });
      } else if (
        this.hasValue(this.StkOut.Mobile) &&
        this.StkOut.Mobile.length > 50
      ) {
        ret = false;
        this.$message({
          message: "手机号码长度不能超过50个字符",
          type: "warning",
        });
      } else if (
        this.hasValue(this.StkOut.Address) &&
        this.StkOut.Address.length > 200
      ) {
        ret = false;
        this.$refs.Address.handleFocus();
        this.$message({
          message: "地址长度不能超过200个字符",
          type: "warning",
        });
      } else if (
        this.hasValue(this.StkOut.Remark) &&
        this.StkOut.Remark.length > 1000
      ) {
        ret = false;
        this.$message({
          message: "发货备注不能超过1000个字符",
          type: "warning",
        });
      }
      return ret;
    },
    // ***** 导入/加载数据******
    // 加载bom配单数据
    LoadBomLine: async function (bomInfo) {
      let orders = await this.$post(CONFIG.bomLineGotoStkOutURL, bomInfo);
      this.BindDataToLineList(orders);
    },
    BindDataToLineList(orders) {
      _.each(orders.DetailList, (item) => {
        item.Price = item.NTQuotePrice === undefined ? null : item.NTQuotePrice;
        item.PriceInTax =
          item.QuotePrice === undefined ? null : item.QuotePrice;
        item.BuyPrice = item.BuyPrice === undefined ? null : item.BuyPrice;
      });
      this.StkOut = _.extend({}, this.StkOut, orders);
      this.StkOut.LineList = orders.DetailList;
      this.getAddress(this.StkOut);
      this.isPopup = true;

      this.$nextTick(function () {
        // this.onBindData()
        // 利润率=(未税单价*汇率-采购价)/采购价 * 100. 显示加上% ， 保留两位小数
        let rate = 1;
        if (this.StkOut && this.StkOut.ExchangeRate) {
          rate = this.toFloat(this.StkOut.ExchangeRate);
        }
        this.itemSource = this.StkOut.LineList;
        _.each(this.itemSource, (item) => {
          let price = this.toFloat(item.Price, 6);
          let buyPrice = this.toFloat(item.BuyPrice, 6);
          item.ProfitMargins = this.GetProfitRate(price, buyPrice, rate); // this.toFixed(price, 2) + '%'
          item.PurchaseAmount = this.CalcAmout(item.Qty, buyPrice);
          item.Amount = this.CalcAmout(
            this.ConvertNumber(item.Qty),
            this.ConvertNumber(item.PriceInTax)
          );
          item.GrossProfit = this.CalcGrossProfit(
            this.ConvertNumber(item.Price),
            this.ConvertNumber(buyPrice),
            this.ConvertNumber(item.Qty),
            null,
            null
          );
        });
        // 计算利润
        this.CalcStkOutAmoutAndGrossProfit(false);
        this.StkOut.IsAdd = true;
        this.ResTaxRate();
      });
      // this.isAllow = false // 编辑禁用选择
    },
    // 从销售单点击发货
    LoadSaleData: async function (info) {
      return this.$post(CONFIG.SalesGotoStkOut, info, (data, logic) => {
        if (logic.code === 200) {
          let rowID = 0;
          _.each(data.LineList, (item, i) => {
            rowID++;
            item.RowGuid = rowID;
            item.SalesModel = item.Model;
            item.Model = item.OutModel;
            item.SOGUID = item.SOGuid;
            item.SOLineGUID = item.SOLineGuid;
            item.Amount = this.CalcAmout(item.Qty, item.PriceInTax);
            //使用备货库存备注
            if (this.IsUseReserveRemark) {
              item.Remark = item.ReserveRemark;
              item.Remark2 = item.ReserveRemark2;
              item.Remark3 = item.ReserveRemark3;
            }
          });
          this.StkOut = _.extend({}, this.StkOut, data);
          this.getAddress(this.StkOut);
          this.StkOut.ShipUserID = this.toInt(data.SalerID);
          // 禁用编辑单元格
          for (var i = 0; i < this.columns.length; i++) {
            if (
              this.columns[i].binding === "SupplierInfo" ||
              this.columns[i].binding === "ShareFee" ||
              this.columns[i].binding === "Remark" ||
              this.columns[i].binding === "Remark2" ||
              this.columns[i].binding === "Remark3" ||
              this.columns[i].binding === "Price" ||
              this.columns[i].binding === "PriceInTax" ||
              this.columns[i].binding === "Discount"
            ) {
              this.columns[i].isReadOnly = false;
            } else {
              this.columns[i].isReadOnly = true;
            }
          }
          this.$nextTick(function () {
            this.itemSource = this.StkOut.LineList;
            this.onBindData();
            this.CalcStkOutAmoutAndGrossProfit(true);
            this.StkOut.IsAdd = true;
          });
        } else {
          return this.$message({ message: logic.msg, type: "error" });
        }
      });
    },
    // 从销售单明细点击发货
    LoadSaleLineData: async function (info) {
      return this.$post(
        CONFIG.GenerateStkOutBySOLineGuids,
        info,
        (data, logic) => {
          if (logic.code === 200) {
            let rowID = 0;
            _.each(data.LineList, (item, i) => {
              rowID++;
              item.RowGuid = rowID;
              item.SalesModel = item.Model;
              item.Model = item.OutModel;
              item.SOGUID = item.SOGuid;
              item.SOLineGUID = item.SOLineGuid;
              item.Amount = this.CalcAmout(item.Qty, item.PriceInTax);
              //使用备货库存备注
              if (this.IsUseReserveRemark) {
                item.Remark = item.ReserveRemark;
                item.Remark2 = item.ReserveRemark2;
                item.Remark3 = item.ReserveRemark3;
              }
            });
            this.StkOut = _.extend({}, this.StkOut, data);
            this.getAddress(this.StkOut);
            this.StkOut.ShipUserID = this.toInt(data.SalerID);
            // 禁用编辑单元格
            for (var i = 0; i < this.columns.length; i++) {
              if (
                this.columns[i].binding === "SupplierInfo" ||
                this.columns[i].binding === "ShareFee" ||
                this.columns[i].binding === "Remark" ||
                this.columns[i].binding === "Remark2" ||
                this.columns[i].binding === "Remark3" ||
                this.columns[i].binding === "Price" ||
                this.columns[i].binding === "PriceInTax" ||
                this.columns[i].binding === "Discount" ||
                this.columns[i].binding === "Qty"
              ) {
                this.columns[i].isReadOnly = false;
              } else {
                this.columns[i].isReadOnly = true;
              }
            }
            this.$nextTick(function () {
              this.itemSource = this.StkOut.LineList;
              this.onBindData();
              this.CalcStkOutAmoutAndGrossProfit(true);
              this.StkOut.IsAdd = true;
            });
          } else {
            return this.$message({ message: logic.msg, type: "error" });
          }
        }
      );
    },
    LoadReturnInfo: async function (info) {
      let stkOuts = await this.$post(CONFIG.StkOutSearchById, info);
      this.StkOut.IsAdd = false;
      this.StkOut = stkOuts;
      this.StkOut.IsReturn = true;
      this.getAddress(this.StkOut);
      this.itemSource = stkOuts.LineList;
      this.isReadonly = true;
      // this.StkOut.stkOutGUID = guid
      this.$nextTick(function () {
        this.onBindData();
        // this.isAllow = false // 编辑禁用选择
        this.StkOut.IsAllowOut = this.StkOut.Status !== 3; // 已出库的禁用保存并出库按钮
      });
    },
    // 编辑订单
    LoadEditData: async function (guid) {
      let stkOuts = await this.$post(CONFIG.StkOutSearchById, {
        StkOutGUID: guid,
      });
      if (stkOuts.IsAudited || stkOuts.Status === 2 || stkOuts.Status === 3) {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
      this.StkOut = stkOuts;
      this.getAddress(this.StkOut);
      this.itemSource = stkOuts.LineList;
      let ssrguid = _.filter(this.itemSource, (item) => {
        return (
          item.SSRGUID !== undefined &&
          item.SSRGUID !== "" &&
          item.SSRGUID !== null
        );
      });
      // 禁用编辑单元格
      if (
        ssrguid !== undefined &&
        ssrguid !== "" &&
        ssrguid !== null &&
        ssrguid.length !== 0
      ) {
        for (var i = 0; i < this.columns.length; i++) {
          if (
            this.columns[i].binding === "SupplierInfo" ||
            this.columns[i].binding === "ShareFee" ||
            this.columns[i].binding === "Remark" ||
            this.columns[i].binding === "Remark2" ||
            this.columns[i].binding === "Remark3"
          ) {
            this.columns[i].isReadOnly = false;
          } else {
            this.columns[i].isReadOnly = true;
          }
        }
      }
      this.isReadonly = true;
      this.StkOut.stkOutGUID = guid;
      this.$nextTick(function () {
        this.onBindData();
        // this.isAllow = false // 编辑禁用选择
        this.StkOut.IsAllowOut = this.StkOut.Status !== 3; // 已出库的禁用保存并出库按钮
      });
    },
    // 根据出库单明细GUID加载实体
    LoadLineAddData: async function (guid, isReturn) {
      let stkOuts = await this.$post(CONFIG.StkOutSearchByLineguid, {
        OutLineGuids: guid,
        IsReturn: isReturn,
      });
      this.StkOut = _.extend({}, this.StkOut, stkOuts);
      this.StkOut.IsReturn = isReturn;
      this.StkOut.IsAdd = true;
      this.StkOut.StkOutGUID = "";
      this.getAddress(this.StkOut);
      this.$nextTick(function () {
        this.itemSource = stkOuts.LineList;
        this.onBindData();
        this.CalcStkOutAmoutAndGrossProfit(true);
        // this.isAllow = false // 编辑禁用选择
        // this.StkOut.IsAllowOut = this.StkOut.Status !== 3 // 已出库的禁用保存并出库按钮
      });
    },
    // 根据我的备货GUID加载实体 后续优化 合并
    LoadReserveLinePL: async function (guid) {
      let stkOuts = await this.$post(CONFIG.GetStkReserveGotoStkOut, {
        ReverseGuid: guid,
      });
      this.StkOut = _.extend({}, this.StkOut, stkOuts);
      this.getAddress(this.StkOut);
      this.itemSource = stkOuts.LineList;
      this.$nextTick(function () {
        this.onBindData();
        this.CalcStkOutAmoutAndGrossProfit(true);
        this.StkOut.IsAdd = true;
        this.isAllow = true; // 新增
        if (!this.hasValue(this.StkOut.TaxRate) || this.StkOut.TaxRate === 0) {
          this.StkOut.TaxRate = this.paramTax;
          this.ResTaxRate();
        }
      });
    },
    // 加载询价记录
    async InquireModel(rows) {
      if (rows.length > 0) {
        let rowID = 0;
        _.each(rows, (stock, i) => {
          let invQty = this.ConvertNumber(invQty);
          // let invQty = stock.InvQty
          // if (stock.InvQty < 0) {
          //   invQty = -stock.InvQty
          // }
          // 判断是否是共享库存ID
          stock.SalesPrice = this.ConvertNumber(stock.Price);

          // if (!_.isNaN(stock.ShareGuid) || !_.isUndefined(stock.ShareGuid) || stock.ShareGuid !== null) {
          //   invQty = stock.ShareQty
          //   if (stock.ShareQty < 0) {
          //     invQty = -stock.ShareQty
          //   }
          //   stock.SalesPrice = this.ConvertNumber(stock.SharePrice)
          // }
          stock.BuyPrice = this.ConvertNumber(stock.Price);
          //税点
          this.StkOut.TaxRate = stock.TaxRate;
          // 页面传递业务逻辑(支持多条)
          let outlinobj = {
            RowGuid: rowID++,
            IsDelete: false,
            Model: stock.Model,
            Qty: invQty,
            SalesQty: invQty,
            InvType: !this.hasValue(stock.InvType)
              ? this.paramQtyType
              : stock.InvType, // 库存类型
            Price: this.CalcPrice(stock.SalesPrice),
            SalesModel: stock.Model,
            PriceInTax: stock.SalesPrice, // 含税价
            Amount: this.CalcAmout(invQty, stock.SalesPrice),
            GrossProfit: this.CalcGrossProfit(
              this.ConvertNumber(stock.SalesPrice),
              this.ConvertNumber(stock.BuyPrice),
              this.ConvertNumber(invQty),
              this.ConvertNumber(stock.shareFee)
            ), // 毛利润
            Brand: stock.Brand,
            Packaging: stock.Packaging,
            MakeYear: stock.MakeYear,
            MPQ: stock.MPQ,
            Quality: stock.Quality,
            DeliveryDate: stock.Leadtime,
            ProductCategory: stock.ProductCategory,
            Unit: !this.hasValue(stock.Unit) ? this.paramQtyUnit : stock.Unit,
            Partner: stock.Partner,
            SupplierInfo: stock.SupplierName,
            BuyPrice: stock.BuyPriceBuyPrice,
            InDate: stock.StkInTime,
            BuyerName: stock.BuyerName,
            Remark: stock.Remark,
            Remark2: stock.Remark1,
            Remark3: stock.Remark2,
            ShareFee: stock.shareFee,
            StorageName: stock.StorageName, // InvPosition
          };
          if (this.isNullItemSource()) {
            this.itemSource = [outlinobj];
          } else {
            this.itemSource.push(outlinobj);
          }
        });
        this.onBindData();
        this.CalcStkOutAmoutAndGrossProfit(true);
      } else {
        this.$message({ message: "加载库存失败，请刷新后重试", type: "error" });
      }
    },
    // 加载供应商库存
    LoadStkVMI: async function (rows) {
      if (rows.length > 0) {
        let rowID = 0;
        _.each(rows, (stocks) => {
          let invQty = stocks.InvQty;
          if (stocks.InvQty < 0) {
            invQty = -stocks.InvQty;
          }
          // 页面传递业务逻辑(支持多条)
          let outlinobj = {
            RowGuid: rowID++,
            IsDelete: false,
            Model: stocks.Model,
            Qty: invQty,
            SalesQty: invQty,
            Price: this.ConvertNumber(stocks.UnitPrice),
            SalesModel: stocks.Model,
            PriceInTax: this.CalcPriceInTax(
              this.ConvertNumber(stocks.UnitPrice)
            ), // 含税价
            Amount: this.CalcAmout(
              this.ConvertNumber(invQty),
              this.ConvertNumber(stocks.UnitPrice)
            ),
            Brand: stocks.Brand,
            Packaging: stocks.Packaging,
            MakeYear: stocks.MakeYear,
            MPQ: stocks.MPQ,
            Quality: stocks.Quality,
            DeliveryDate: stocks.Leadtime,
            ProductCategory: stocks.ProductCategory,
            Unit: !this.hasValue(stocks.Unit) ? this.paramQtyUnit : stocks.Unit,
            InvType: !this.hasValue(stocks.InvType)
              ? this.paramQtyType
              : stocks.InvType, // 库存类型
            Partner: stocks.Partner,
            SupplierInfo: stocks.SupplierName,
            BuyPrice: stocks.BuyPrice,
            PurchaseAmount: this.CalcAmout(
              this.ConvertNumber(invQty),
              this.ConvertNumber(stocks.BuyPrice)
            ),
            InDate: stocks.StkInTime,
            BuyerName: stocks.BuyerName,
            Remark: stocks.Remark,
            Remark2: stocks.Remark1,
            Remark3: stocks.Remark2,
            ShareFee: stocks.shareFee,
            StorageName: stocks.StorageName, // InvPosition,
            GrossProfit: this.CalcGrossProfit(
              this.ConvertNumber(stocks.UnitPrice),
              this.ConvertNumber(stocks.BuyPrice),
              this.ConvertNumber(invQty)
            ),
          };
          if (this.isNullItemSource()) {
            this.itemSource = [outlinobj];
          } else {
            this.itemSource.push(outlinobj);
          }
        });
        this.onBindData();
        this.CalcStkOutAmoutAndGrossProfit(true);
      } else {
        this.$message({
          message: "加载供应商库存失败，请刷新后重试",
          type: "error",
        });
      }
    },
    // 加载供应商库存
    LoadStkVMIGUID: async function (guid) {
      let stkVMIS = await this.$post(CONFIG.GetStkVMIByGUIDS, {
        VMGUIDS: guid,
      });
      this.$nextTick(function () {
        _.each(stkVMIS, (items) => {
          this.$set(items, "BuyPrice", items.UnitPrice);
          this.$set(items, "UnitPrice", items.SalesPrice);
          this.$set(items, "InvQty", "");
          this.$set(items, "SupplierName", items.SupplierName);
        });
        this.LoadStkVMI(stkVMIS);
      });
    },
    // 加载历史发货明细
    loadStkOutLine(rows) {
      if (rows.length > 0) {
        let rowID = 0;
        _.each(rows, (stocks) => {
          // 页面传递业务逻辑(支持多条)
          let outlinobj = {
            RowGuid: rowID++,
            IsDelete: false,
            Model: stocks.Model,
            Qty: stocks.Qty,
            SalesQty: stocks.Qty,
            Price: this.ConvertNumber(stocks.Price),
            SalesModel: stocks.Model,
            PriceInTax: this.CalcPriceInTax(this.ConvertNumber(stocks.Price)), // 含税价
            Amount: this.CalcAmout(
              this.ConvertNumber(stocks.Qty),
              this.ConvertNumber(stocks.Price)
            ),
            InvType: !this.hasValue(stocks.InvType)
              ? this.paramQtyType
              : stocks.InvType, // 库存类型
            Unit: !this.hasValue(stocks.Unit) ? this.paramQtyUnit : stocks.Unit,
            Brand: stocks.Brand,
            Packaging: stocks.Packaging,
            MakeYear: stocks.MakeYear,
            MPQ: stocks.MPQ,
            Quality: stocks.Quality,
            ProductCategory: stocks.ProductCategory,
          };
          if (this.isNullItemSource()) {
            this.itemSource = [outlinobj];
          } else {
            this.itemSource.push(outlinobj);
          }
        });
        this.onBindData();
        this.CalcStkOutAmoutAndGrossProfit(true);
      } else {
        this.$message({
          message: "加载发货明细失败，请刷新后重试",
          type: "error",
        });
      }
    },
    // 加载报价明细
    loadQuotedPriceLine(rows) {
      if (rows.length > 0) {
        let rowID = 0;
        _.each(rows, (stocks) => {
          // 页面传递业务逻辑(支持多条)
          let outlinobj = {
            RowGuid: rowID++,
            IsDelete: false,
            Model: stocks.Model,
            Qty: stocks.Qty,
            SalesQty: stocks.Qty,
            Price: this.ConvertNumber(stocks.QuotePrice),
            SalesModel: stocks.Model,
            PriceInTax: this.CalcPriceInTax(
              this.ConvertNumber(stocks.QuotePrice)
            ), // 含税价
            Amount: this.CalcAmout(
              this.ConvertNumber(stocks.Qty),
              this.ConvertNumber(stocks.QuotePrice)
            ),
            Unit: !this.hasValue(stocks.Unit) ? this.paramQtyUnit : stocks.Unit,
            InvType: !this.hasValue(stocks.InvType)
              ? this.paramQtyType
              : stocks.InvType, // 库存类型
            Brand: stocks.Brand,
            Packaging: stocks.Packaging,
            MakeYear: stocks.MakeYear,
            MPQ: stocks.MPQ,
            Quality: stocks.Quality,
            ProductCategory: stocks.ProductCategory,
          };
          if (this.isNullItemSource()) {
            this.itemSource = [outlinobj];
          } else {
            this.itemSource.push(outlinobj);
          }
        });
        this.onBindData();
        this.CalcStkOutAmoutAndGrossProfit(true);
      } else {
        this.$message({
          message: "加载报价明细失败，请刷新后重试",
          type: "error",
        });
      }
    },
    isNullItemSource() {
      return (
        this.itemSource.length === 0 ||
        (this.itemSource.length < 2 &&
          this.itemSource[0].Model === "" &&
          this.itemSource[0].Qty === null &&
          this.itemSource[0].SalesModel === "")
      );
    },
    // 批次库存 销售出货
    LoadListByStock: async function (stkline, isChecklist) {
      // 根据传入的stock对象加载明细列表
      // 库存出货带上数量
      let StockOutQty = true;
      let value = this.getCompanySetupValue("StockOutQty");
      if (value !== "1") {
        StockOutQty = false;
      }
      this.StkOut.CustomerName = stkline[0].CustomerName;
      this.StkOut.Contact = stkline[0].Contact;
      this.StkOut.Telephone = stkline[0].Telephone;
      this.StkOut.Mobile = stkline[0].Mobile;
      this.getAddress(stkline[0]);
      if (stkline.length > 0) {
        let rowID = 0;
        _.each(stkline, (stocks) => {
          let invQty = stocks.UsableQty;
          if (invQty < 0) {
            invQty = "";
          }
          if (!StockOutQty) {
            invQty = "";
          }
          let price = stocks.SalesPrice;
          if (!this.hasValue(stocks.SalesPrice)) {
            price = stocks.Price;
          }
          // 页面传递业务逻辑(支持多条)
          let outlinobj = {
            RowGuid: rowID++,
            IsDelete: false,
            StkGUID: stocks.StkGUID, // 库存GUID
            Model: stocks.Model,
            Qty: invQty,
            SalesQty: invQty,
            InvType: !this.hasValue(stocks.InvType)
              ? this.paramQtyType
              : stocks.InvType, // 库存类型
            Price: this.ConvertNumber(price),
            SalesModel: stocks.Model,
            PriceInTax: this.CalcPriceInTax(this.ConvertNumber(price)), // 含税价
            Amount: this.CalcAmout(
              this.ConvertNumber(invQty),
              this.ConvertNumber(price)
            ),
            GrossProfit: this.CalcGrossProfit(
              this.ConvertNumber(price),
              this.ConvertNumber(stocks.BuyPrice),
              this.ConvertNumber(invQty),
              this.ConvertNumber(stocks.shareFee)
            ), // 毛利润
            PurchaseAmount: this.CalcAmout(invQty, stocks.BuyPrice),
            Brand: stocks.Brand,
            Packaging: stocks.Packaging,
            MakeYear: stocks.MakeYear,
            MPQ: stocks.MPQ,
            Quality: stocks.Quality,
            DeliveryDate: stocks.Leadtime,
            ProductCategory: stocks.ProductCategory,
            Unit: !this.hasValue(stocks.Unit) ? this.paramQtyUnit : stocks.Unit,
            Partner: stocks.Partner,
            SupplierInfo: stocks.SupplierName,
            BuyPrice: stocks.BuyPrice,
            InDate: stocks.StkInTime,
            BuyerName: stocks.BuyerName,
            Remark: stocks.Remark,
            Remark2: stocks.Remark1,
            Remark3: stocks.Remark2,
            ShareFee: stocks.shareFee,
            StorageName: stocks.StorageName, // InvPosition
            InvPosition: stocks.InvPosition,
            CustomerPartNo: stocks.CustomerPartNo,
            Place: stocks.Place,
          };
          if (this.isNullItemSource()) {
            this.itemSource = [outlinobj];
          } else {
            this.itemSource.push(outlinobj);
          }
        });
        // this.StkOut.ShipUserID = 1
        // console.log(this.StkOut, '---00000000000000')
        this.onBindData();
        this.CalcStkOutAmoutAndGrossProfit(true);
      } else {
        // 批次库存可不选库存直接添加 销售出库 和 采购入库
        // this.$message({ message: '加载库存失败，请刷新后重试', type: 'error' })
      }
    },
    // ***** 导入/加载数据******
    onDeletedRows() {
      let rows = this.$refs.flexGrid.getSelectedRows();
      _.each(rows, (data) => {
        _.each(this.itemSource, (item, i) => {
          if (_.isEqual(_.omit(item, "ShareFee"), _.omit(data, "ShareFee"))) {
            this.itemSource.splice(i, 1);
            return;
          }
        });
      });
      this.StkOut.LineList = this.itemSource;
      this.CalcPay(false);
      this.CalcStkOutAmoutAndGrossProfit(false);
    },
    hasRes(code) {
      return _.some(this.resourceList, (m) => m === code);
    },
    CalcPriceInTax(price) {
      // 计算含税价
      return this.toFloat(price * (1 + Number(this.StkOut.TaxRate) / 100), 6);
    },
    CalcPrice(priceintax) {
      // 计算未税
      return this.toFloat(
        priceintax / (1 + Number(this.StkOut.TaxRate) / 100),
        6
      );
    },
    CalcAmout(qty, salesPrice) {
      // 计算金额
      return this.toFloat(qty * salesPrice, 2);
    },
    CalcPay(isEdit) {
      // 计算应付金额
      this.StkOut.Amount = 0;
      _.each(isEdit ? this.StkOut.LineList : this.itemSource, (o, index) => {
        this.StkOut.Amount += this.toFloat(o.Amount, 2);
      });

      // this.StkOut.Amount = (this.StkOut.Amount).toFixed(2)
    },
    CalcGrossProfit(price, buyprice, qty, shareFee, discountAmount) {
      // 计算毛利润
      let rate = 1;
      if (this.StkOut && this.StkOut.ExchangeRate) {
        rate = this.toFloat(this.StkOut.ExchangeRate);
      }
      let profit = (this.toFloat(price) * rate - this.toFloat(buyprice)) * qty;
      profit = profit - this.toFloat(shareFee) - this.toFloat(discountAmount);
      return this.toFloat(profit, 2);
    },
    // 重置页面 清除数据
    onResetStkout() {
      let info = {
        BillNo: "", // 销售单号
        ShipTime: new Date().toLocaleDateString(), // 出货日期
        ShipUserID: this.$store.state.accountInfo.UserID, // 销售员ID
        Shipper: this.$store.state.accountInfo.UserName, // 销售员
        Amount: null, // 应收金额
        CustomerGUID: "", // 客户guid
        CustomerName: "", // 客户名称
        ReceiptType: null, // 收款方式
        ReceivedAmount: null, // 实收金额
        CustomerBankNo: null, // 收款帐户
        Address: null, // 收货地址
        ExpectReceiptDate: null, // 预计收款日期
        GrossProfit: null, // 利润总额
        DiscountAmount: null, // 折扣金额
        Contact: "", // 联系人
        Telephone: "", // 联系电话
        Mobile: "", // 手机号码
        CustomerOrderNo: "", // 客户订单号
        TaxRate: null, // 税点
        OtherFeeAmount: null, // 其它费用
        TransportCompany: "", // 快递公司
        ShippingNo: "", // 快递单号
        ExpressAccount: "", // 快递账号
        CurrencyCode: "", // 币种
        ExchangeRate: "1", // 汇率
        Remark: "", // 销售备注
        LineList: [], // 出库单详情
        IsAdd: true, // 是否新增
        IsAllowOut: true, // 是否允许出库
        CBGUID: "", // 收款账号,
        StkOutGUID: "", // 发货单ID
        StkOutID: 0,
      };
      this.$refs.Address.clear();
      this.$refs.Address.clear();
      this.$refs.Address.clear();
      this.StkOut = _.extend({}, this.StkOut, info);
      this.itemSource = [];
    },
    // 单元格结束触发事件
    onCellEdited(
      aftervalue,
      beforevalue,
      colname,
      gridobj,
      rowindex,
      colindex
    ) {
      // 1.修改后值 2.修改前值 3.列明 4.表格对象 5.行索引 6.列索引
      // if (!this.hasValue(aftervalue)) {
      //   return;
      // }
      if (!gridobj) return;
      let rowData = gridobj.row;

      /*************处理大小写配置start*************/
      //要处理大小写的列
      const handleColumns = [
        "Model",
        "SalesModel",
        "Brand",
        "Packaging",
        "MakeYear",
      ];
      if (
        !this.CompanySetup.EnableNotUpperCase &&
        handleColumns.includes(colname)
      ) {
        aftervalue = aftervalue.toUpperCase();
        rowData[colname] = aftervalue;
      }
      /*************处理大小写配置end***************/

      if (colname === "Model") {
        if (
          this.StkOut.IsTakeOutPrint &&
          this.hasValue(this.StkOut.CustomerName)
        ) {
          // 根据型号获取上次价格
          let data = _.extend(
            {},
            { Model: rowData.Model, CustomerName: this.StkOut.CustomerName }
          );
          this.$post(CONFIG.GetPrice, data, (data) => {
            let price = this.toFloat(data, 6); // 未税价格

            let priceintaxSummary = this.CalcPriceInTax(price);
            rowData["Price"] = price;
            rowData["PriceInTax"] = priceintaxSummary;
          });
        }

        // 发货型号
        if (beforevalue === "" && aftervalue !== "") {
          rowData["SalesModel"] = aftervalue;
        } else if (beforevalue !== "" && aftervalue !== "") {
          rowData["SalesModel"] = aftervalue;
        } else if (
          beforevalue !== "" &&
          aftervalue === "" &&
          beforevalue !== undefined
        ) {
          rowData["SalesModel"] = aftervalue;
        }
      }
      if (colname === "Price") {
        // 由未税计算出含税价
        let _priceIntax = this.CalcPriceInTax(aftervalue);
        rowData["PriceInTax"] = _priceIntax;
      }
      if (colname === "PriceInTax") {
        // 含税算未税
        // 由含税计算出未税价
        let _price = this.CalcPrice(aftervalue);
        rowData["Price"] = _price;
      }
      if (colname === "Qty") {
        let numbers = this.ConvertNumber(aftervalue);
        let buyprice = this.ConvertNumber(rowData.BuyPrice);
        rowData["PurchaseAmount"] = this.CalcAmout(numbers, buyprice);
      }
      // 采购价算采购总额
      if (colname === "BuyPrice") {
        let numbers = this.ConvertNumber(rowData.Qty);
        let buyprice = this.ConvertNumber(aftervalue);
        rowData["PurchaseAmount"] = this.CalcAmout(numbers, buyprice);
      }
      if (
        colname === "Price" ||
        colname === "PriceInTax" ||
        colname === "Qty" ||
        colname === "BuyPrice" ||
        colname === "ShareFee" ||
        colname === "Discount"
      ) {
        let numbers = this.toFloat(rowData["Qty"]);
        let priceInTax = this.toFloat(rowData["PriceInTax"]);

        let amount = this.CalcAmout(numbers, priceInTax);
        rowData["Amount"] = this.toFloat(amount, 2);

        let price = this.ConvertNumber(rowData["Price"]);
        let buyprice = this.ConvertNumber(rowData["BuyPrice"]);
        let shareFee = this.ConvertNumber(rowData["ShareFee"]);

        let discount = this.ConvertNumber(rowData.Discount);
        discount = !discount ? 1 : discount / 100;
        let discountAmount = this.CalcDiscountLineAmount(amount, discount);
        rowData["DiscountAmount"] = discountAmount;
        this.StkOut.DiscountAmount = this.CalcDiscountAmount(gridobj.rows);
        let grossSummary = this.CalcGrossProfit(
          price,
          buyprice,
          numbers,
          shareFee,
          discountAmount
        );
        rowData["GrossProfit"] = grossSummary;
        this.CalcStkOutAmoutAndGrossProfit(false);
      }

      if (colname === "Discount") {
        let discount = this.ConvertNumber(aftervalue);
        discount = discount === 0 ? 100 : discount;
        discount = discount / 100;
        let lineAmount = this.ConvertNumber(rowData["Amount"]);
        rowData["DiscountAmount"] = this.CalcDiscountLineAmount(
          lineAmount,
          discount
        );
        this.StkOut.DiscountAmount = this.CalcDiscountAmount(gridobj.rows);
        this.StkOut.GrossProfit = this.StkOut.ProfitMargins;
        this.CalcStkOutAmoutAndGrossProfit(false);
      }

      //判断是否为最后一行，并且“新增一行”按钮启用，自动添加空行
      if (
        rowindex == gridobj.rows.length - 1 &&
        !this.isDisabled &&
        this.isAllow
      ) {
        this.addNewRow();
      }
    },
    CalcDiscountLineAmount(lineAmount, discount) {
      let discountAmount = (1 - discount) * lineAmount;
      if (discountAmount > 0)
        return this.toFloat(lineAmount - discountAmount, 2);
    },
    CalcDiscountAmount(rows) {
      let discountAmount = 0.0;
      if (rows && rows.length > 0) {
        _.each(rows, (row, index) => {
          if (!_.isUndefined(row.DiscountAmount))
            discountAmount += row.DiscountAmount;
        });
      }
      return discountAmount;
    },
    CalcStkOutAmoutAndGrossProfit(isEdit) {
      // 计算金额和毛利润, 分摊费 ..
      this.StkOut.Amount = 0.0; // 应收
      this.StkOut.GrossProfit = 0.0; // 利润总额
      this.StkOutAmount = 0.0;
      _.each(isEdit ? this.StkOut.LineList : this.itemSource, (o, index) => {
        if (this.ConvertNumber(this.StkOut.TaxRate) > 0) {
          this.StkOut.Amount += this.toFloat(o.PriceInTax * o.Qty, 2);
        } else {
          this.StkOut.Amount += this.toFloat(o.Amount, 2);
        }
        this.StkOutAmount += this.toFloat(o.Amount, 2);
        this.StkOut.GrossProfit += this.toFloat(o.GrossProfit, 2);
      });
      let GrossProfitSummary =
        this.StkOut.GrossProfit -
        // this.ConvertNumber(this.StkOut.DiscountAmount) -
        this.ConvertNumber(this.StkOut.OtherFeeAmount);
      this.StkOut.GrossProfit = this.toFloat(GrossProfitSummary, 2);
      this.StkOut.Amount = this.toFloat(this.StkOut.Amount, 2);
      // this.StkOut.Amount = this.toFloat(this.StkOut.Amount - this.ConvertNumber(this.StkOut.DiscountAmount), 2)
      if (this.StkOut.ReceiptType === "现金") {
        this.StkOut.ReceivedAmount = this.toFloat(this.StkOut.Amount, 2);
      }

      this.StkOutReceiveAmount = this.toFloat(
        this.StkOut.Amount - this.StkOut.DiscountAmount,
        2
      );
      this.CalcProfitMargins();
    },

    CalcTaxRate(val) {
      // Qty 数量
      // Price 未税
      // PriceInTax 含税
      // Amount 小计金额
      // GrossProfit 利润
      //  console.log('this.StkOut.TaxRate2222 ', this.StkOut.TaxRate)
      val ? (this.StkOut.TaxRate = val.key) : (this.StkOut.TaxRate = null);

      if (this.itemSource.length > 0) {
        const source = this.itemSource;
        for (let i = 0; i < source.length; i++) {
          // 计算未税价和含税价和金额
          if (this.hasValue(source[i].Price)) {
            // 计算含税
            let _priceIntax = this.CalcPriceInTax(source[i].Price);
            source[i].PriceInTax = _priceIntax;
          } else if (this.hasValue(source[i].PriceInTax)) {
            // 含税算未税
            let _price = this.CalcPrice(source[i].PriceInTax);
            source[i].Price = _price;
          }
          source[i].Amount = this.CalcAmout(
            source[i].Qty,
            source[i].PriceInTax
          ).toFixed(2);
          source[i].GrossProfit = this.CalcGrossProfit(
            source[i].Price,
            source[i].BuyPrice,
            source[i].Qty,
            source[i].ShareFee,
            source[i].Discount
          ).toFixed(2);
        }
        this.itemSource = JSON.parse(JSON.stringify(source));
        this.CalcStkOutAmoutAndGrossProfit(false);
      }
    },
    CalcProfitMargins() {
      let rate = 1;
      if (this.StkOut && this.StkOut.ExchangeRate) {
        rate = this.toFloat(this.StkOut.ExchangeRate);
      }
      let tempSource = this.itemSource;

      if (tempSource.length > 0) {
        for (let i = 0; i < tempSource.length; i++) {
          // 利润率=(未税单价*汇率*折扣-采购价)/采购价 * 100. 显示加上% ， 保留两位小数
          let price = this.toFloat(tempSource[i].Price);
          let buyPrice = this.toFloat(tempSource[i].BuyPrice);
          // let discount = this.toFloat(tempSource[i].Discount);
          // discount = discount === 0 ? 1 : discount / 100;
          // price = price * discount; // 折扣价
          if (price > 0 && rate > 0) {
            // price = (price * rate - buyPrice) / price * 100
            tempSource[i].ProfitMargins = this.GetProfitRate(
              price,
              buyPrice,
              rate
            );
          } else {
            tempSource[i].ProfitMargins = "";
          }
        }
        this.itemSource = tempSource;
      }
    },
    SearchCompany() {
      this.InTypes = "1,3"; // 客户和供应商客户
      if (!this.fromSalesOrderOpen) {
        this.dialogCompanySelectVisible = true;
      }
    },
    companyChecked(item) {
      this.getAddress(item);
      if (this.hasValue(item.CompanyName)) {
        this.StkOut.CustomerName = item.CompanyName;
      }
      if (this.hasValue(item.CompanyID)) {
        this.StkOut.CustomerGUID = item.CompanyID;
      }
      if (this.hasValue(item.ContactName)) {
        this.StkOut.Contact = item.ContactName;
      }
      if (this.hasValue(item.Telephone)) {
        this.StkOut.Telephone = item.Telephone;
      }
      // 默认跟进人，跟进人为空，默认当前登录用户 ,跟进人为多个取第一个。
      const regex = /,|，/; // 逗号
      if (this.hasValue(item.FollowUserName)) {
        let idList = item.FollowUserName.split(regex);
        let followUserName = "";
        if (idList.length > 1) {
          followUserName = idList[0];
        } else {
          followUserName = item.FollowUserName;
        }
        if (this.hasValue(followUserName)) {
          let info = _.find(this.selectOptionsAllUserURL, (item) => {
            return item.value === followUserName;
          });
          if (this.hasValue(info) && this.hasValue(info.key)) {
            this.StkOut.ShipUserID = info.key;
            this.StkOut.Shipper = info.value;
          }
        }
      }
      if (!this.hasValue(this.StkOut.ShipUserID)) {
        this.StkOut.ShipUserID = this.$store.state.accountInfo.UserID;
        this.StkOut.Shipper = this.$store.state.accountInfo.UserName;
      }

      if (this.hasValue(item.CurrencyCode)) {
        this.StkOut.CurrencyCode = item.CurrencyCode;
      }
      this.StkOut.ReceiptType = item.PaymentType;
      this.CurrencyCodeChange();

      if (this.hasValue(item.TaxRate) && this.StkOut.TaxRate !== item.TaxRate) {
        this.StkOut.TaxRate = item.TaxRate;
        this.CalcTaxRate({ key: item.TaxRate });
      }
      if (this.hasValue(item.Mobile)) {
        this.StkOut.Mobile = item.Mobile;
      }
    },
    getAddress(item) {
      this.selectOptionsAddress = [];
      // 获取地址信息组合下拉框数据
      if (this.hasValue(item.DeliveryAddress)) {
        this.selectOptionsAddress = this.selectOptionsAddress.concat({
          key: item.DeliveryAddress,
          value: item.DeliveryAddress,
        });
      }
      if (this.hasValue(item.Address)) {
        this.selectOptionsAddress = this.selectOptionsAddress.concat({
          key: item.Address,
          value: item.Address,
        });
      }
      if (this.hasValue(item.Address2)) {
        this.selectOptionsAddress = this.selectOptionsAddress.concat({
          key: item.Address2,
          value: item.Address2,
        });
      }
      if (this.hasValue(item.Address3)) {
        this.selectOptionsAddress = this.selectOptionsAddress.concat({
          key: item.Address3,
          value: item.Address3,
        });
      }
      // 获取地址信息 如果存在货运地址默认货运地址
      if (this.hasValue(item.DeliveryAddress)) {
        this.StkOut.Address = item.DeliveryAddress;
      }
      // 获取地址信息 如果没有货运地址默认公司地址
      if (!this.hasValue(item.DeliveryAddress) && this.hasValue(item.Address)) {
        this.StkOut.Address = item.Address;
      }
      // 获取地址信息 如果公司地址地址默认Address1
      if (!this.hasValue(item.Address) && this.hasValue(item.Address2)) {
        this.StkOut.Address = item.Address2;
      }
      // 获取地址信息 如果没有Address1默认Address2
      if (!this.hasValue(item.Address2) && this.hasValue(item.Address3)) {
        this.StkOut.Address = item.Address3;
      }
      // 如果没有地址信息，清空上次输入的地址信息
      if (
        !this.hasValue(item.Address) &&
        !this.hasValue(item.Address2) &&
        !this.hasValue(item.Address3) &&
        !this.hasValue(item.DeliveryAddress)
      ) {
        this.$refs.Address.clear();
      }
    },
    hasValue(val) {
      if (val === null || val === undefined || val === "") {
        return false;
      }
      return true;
    },
    onSaleChange() {
      // 销售员选中事件
      let buyer = _.find(this.selectOptionsAllUserURL, (item) => {
        return item.key === Number(this.StkOut.ShipUserID);
      });
      if (!_.isUndefined(buyer)) {
        this.StkOut.Shipper = buyer.value;
      }
    },
    onBillIconClick() {
      if (this.StkOut.IsAdd) {
        this.IsManual = !this.IsManual;
        if (!this.IsManual) {
          this.StkOut.BillNo = "";
        }
      } else {
        this.$message({ message: "单据编号生成后不可以修改！", type: "error" });
      }
    },
    autocompleteChange(val) {
      this.startTime = new Date();
      _.delay(() => {
        this.endTime = new Date();
        if (this.endTime - this.startTime >= 800) {
          this.autocompleteSearch(true, val);
        }
      }, 800);
    },
    autocompleteSearch(isSearch, data) {
      if (!isSearch) return;
      this.autocompleteSearchData = data;
      if (data.length > 0) {
        // this.autocompleteShow = true
        // this.$refs.autocompleteSelect.$el.style.left = getabsleft(this.$refs.znlAutocomplete.$el) + 65 + 'px'
        // this.$refs.autocompleteSelect.$el.style.top = getabstop(this.$refs.znlAutocomplete.$el) + 30 + 'px'
      } else {
        this.autocompleteShow = false;
      }
    },
    selectionChanged(val) {
      this.autocompleteShow = false;
      this.companyChecked([val]);
    },
    // 是否使用备货库存备注保存到参数配置
    onUseReserveRemarkValue() {
      let datas = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn && paramjosn.ParamCode === "IsUseReserveRemark") {
          return paramjosn;
        }
      });
      if (!this.hasValue(datas)) {
        datas = {
          ParamText: "是否使用备货库存备注",
          ParamCode: "IsUseReserveRemark",
          ParamValue: "",
          ParamType: "",
        };
      }
      datas.ParamValue = this.IsUseReserveRemark ? 1 : 0;
      this.$post(
        CONFIG.SavePageParamURL,
        { GCode: this.GCode, ParamJson: [datas] },
        (data) => {
          localStorage.removeItem("stkOut");
        }
      );
    },
    // 连续录单模式保存到参数配置
    onContinuousRecordValue() {
      let datas = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn && paramjosn.ParamCode === "IsContinuousRecord") {
          return paramjosn;
        }
      });
      if (this.hasValue(datas)) {
        datas.ParamValue = this.IsContinuousRecord ? 1 : 0;
        this.$post(
          CONFIG.SavePageParamURL,
          { GCode: this.GCode, ParamJson: [datas] },
          (data) => {
            localStorage.removeItem("stkOut");
          }
        );
      }
    },
    // 自动打印保存到参数配置
    onPrintValue() {
      let datas = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn && paramjosn.ParamCode === "IsAutoPrint") {
          return paramjosn;
        }
      });
      if (this.hasValue(datas)) {
        datas.ParamValue = this.IsPrint ? 1 : 0;
        this.$post(
          CONFIG.SavePageParamURL,
          { GCode: this.GCode, ParamJson: [datas] },
          (data) => {
            localStorage.removeItem("StkOut");
          }
        );
      }
    },
    async checkLineSalesPriceAndInvQty() {
      let ret = true;
      if (
        this.itemSource === undefined ||
        this.itemSource === null ||
        this.itemSource.length <= 0
      ) {
        ret = false;
        this.$message({ message: "请添加出库单详情", type: "warning" });
      }
      let dontAllowNegativeStock = this.ConvertNumber(
        this.getCompanySetupValue("DontAllowNegativeStock")
      );
      let notPriceTooLower = this.ConvertNumber(
        this.getCompanySetupValue("NotPriceTooLower")
      );
      // 不允许负库存出库 -- 系统参数
      if (this.StkOut.Status !== 3 && dontAllowNegativeStock === 1) {
        _.each(this.itemSource, (item) => {
          if (
            item.StkGUID === undefined ||
            item.StkGUID === null ||
            item.StkGUID === ""
          ) {
            this.$message({
              message:
                "型号:" +
                item.Model +
                " 没有关联库存,不允许负库存出库,请从库存列表中选中后再出库！",
              type: "warning",
            });
            ret = false;
            return;
          }
        });
      }
      // 不允许负库存出库 || 不允许低于销售定价出库  -- 系统参数
      if (
        this.StkOut.Status !== 3 &&
        (dontAllowNegativeStock === 1 || notPriceTooLower === 1)
      ) {
        let stkGUIDs = _.map(this.itemSource, (row) => {
          return row["StkGUID"];
        });
        let lstStock = [];
        let stkGUIDlist = _.filter(stkGUIDs, function (item) {
          return item;
        });
        var serarchReserveLockQty = false;
        if (dontAllowNegativeStock === 1) {
          serarchReserveLockQty = true;
        }
        var json = {
          StkGUIDS: stkGUIDlist,
          IsSerarchReserveLockQty: serarchReserveLockQty,
        };
        await this.$post(CONFIG.GetStklistURL, json, (data) => {
          lstStock = _.map(data, function (item) {
            return {
              StkGUID: item.StkGUID,
              SalesPrice: item.SalesPrice,
              InvQty: item.InvQty,
              ReserveLockQty: item.ReserveLockQty,
            };
          });
        });

        let exchange = this.StkOut.ExchangeRate;
        _.each(this.itemSource, (item) => {
          let info = _.find(lstStock, (s) => {
            return s.StkGUID === item.StkGUID;
          });
          if (info !== undefined) {
            let msg = "";
            // 验证 是否低于销售定价
            if (notPriceTooLower === 1) {
              let salesPrice =
                info.SalesPrice === undefined ? 0 : info.SalesPrice;
              // 含税价
              let priceInTax =
                item.PriceInTax === undefined ? 0 : item.PriceInTax;
              if (priceInTax * exchange < salesPrice) {
                msg =
                  "型号:" +
                  item.Model +
                  " 销售定价:" +
                  salesPrice +
                  "，销售价:" +
                  priceInTax +
                  " 低于销售定价,不允许出库！";
                this.$message({ message: msg, type: "warning" });
                ret = false;
              }
            }
            // 验证 是否负库存出库
            if (dontAllowNegativeStock === 1) {
              let invQty = info.InvQty === undefined ? 0 : info.InvQty;
              let reserveLockQty =
                info.ReserveLockQty === undefined ? 0 : info.ReserveLockQty;
              if (
                (this.hasValue(item.SRGUID) && invQty < item.Qty) ||
                (!this.hasValue(item.SRGUID) &&
                  invQty - reserveLockQty < item.Qty)
              ) {
                msg =
                  "型号:" +
                  item.Model +
                  " 库存量不足(当前库存量:" +
                  invQty +
                  ",出货量:" +
                  item.Qty +
                  "),不允许负库存出库！";
                if (reserveLockQty > 0) {
                  msg =
                    "型号:" +
                    item.Model +
                    " 库存量不足(当前库存量:" +
                    invQty +
                    ",备货锁定量:" +
                    reserveLockQty +
                    ",出货量:" +
                    item.Qty +
                    "),不允许负库存出库！";
                }
                this.$message({ message: msg, type: "warning" });
                ret = false;
                return;
              }
            }
          }
        });
      }
      return ret;
    },
    changeAddressInputHandler(value) {
      const selectValue = value.target.value;
      let addres = _.find(this.selectOptionsAddress, function (item) {
        return item.key === selectValue;
      });
      if (!this.hasValue(addres) && _.trim(selectValue) !== "") {
        this.selectOptionsAddress = this.selectOptionsAddress.concat({
          key: selectValue,
          value: selectValue,
        });
      }
      this.StkOut.Address = selectValue;
    },
    isMustFill(fieldName) {
      // console.log(fieldName)
      if (this.mustFillFieldList && this.mustFillFieldList.length > 0) {
        return this.mustFillFieldList.indexOf(fieldName) > -1;
      }
      return false;
    },

    // 淘宝订单
    async loadByTaobao(row) {
      if (row === undefined || row === null) {
        return;
      }
      if (this.hasValue(row.PostFee) && row.PostFee > 0) {
        // this.StkOut.OtherFeeAmount = row.PostFee;
        this.StkOut.Remark = "邮费：" + row.PostFee;
      }
      this.StkOut.OrderFrom = "淘宝";
      this.StkOut.CustomerName = row.BuyerNick;
      this.StkOut.Contact = row.ReceiverName;
      this.StkOut.Mobile = row.ReceiverMobile;
      let recAddress = "";
      if (this.hasValue(row.ReceiverState)) {
        recAddress = row.ReceiverState;
      }
      if (this.hasValue(row.ReceiverCity)) {
        recAddress = recAddress + row.ReceiverCity;
      }
      if (this.hasValue(row.ReceiverDistrict)) {
        recAddress = recAddress + row.ReceiverDistrict;
      }
      if (this.hasValue(row.ReceiverAddress)) {
        recAddress = recAddress + row.ReceiverAddress;
      }
      this.StkOut.Address = recAddress;
      if (row.Orders === undefined || row.Orders === null) {
        return;
      }
      let lines = JSON.parse(row.Orders);
      if (lines === undefined || lines === null || lines.length <= 0) {
        return;
      }
      let oids = [];
      _.each(lines, (item) => {
        if (this.hasValue(item.OidStr) && oids.indexOf(item.OidStr) === -1) {
          oids.push(item.OidStr);
        }
      });
      let resultStock = await this.$post(CONFIG.GetLinkedStockList, {
        OidStrs: oids,
      });
      _.each(resultStock, (item) => {
        let qty = this.ConvertNumber(item.Qty);
        if (qty < 0) {
          qty = null;
        }
        let line = {
          TidStr: item.TidStr,
          OidStr: item.OidStr,
          StkGUID: item.StkGUID, // 库存GUID
          Model: item.Model,
          SalesModel: this.subStrStkValue(item.SalesModel),
          Qty: qty,
          SalesQty: qty,
          Price: item.Price,
          Remark: item.Title,
          CustomerOrderNo: item.TidStr,

          Brand: item.Brand,
          Packaging: item.Packaging,
          MakeYear: item.MakeYear,
          MPQ: item.MPQ,
          Quality: item.Quality,
          SupplierInfo: item.SupplierName,
          BuyPrice: item.BuyPrice,
          InDate: item.StkInTime,
          BuyerName: item.BuyerName,
          StorageName: item.StorageName,
          InvPosition: item.InvPosition,
          ProductCategory: item.ProductCategory,
          Unit: !this.hasValue(item.Unit) ? this.paramQtyUnit : item.Unit,
          PurchaseAmount: this.CalcAmout(qty, item.BuyPrice),
          InvType: !this.hasValue(item.InvType)
            ? this.paramQtyType
            : item.InvType, // 库存类型

          PriceInTax: this.CalcPriceInTax(item.Price), // 含税价
          Amount: this.CalcAmout(qty, item.Price),
          GrossProfit: this.CalcGrossProfit(
            item.Price,
            this.ConvertNumber(item.BuyPrice),
            this.ConvertNumber(qty),
            this.ConvertNumber(item.ShareFee)
          ), // 毛利润
        };
        if (this.isNullItemSource()) {
          this.itemSource = [line];
        } else {
          this.itemSource.push(line);
        }
        this.StkOut.CustomerOrderNo = line.CustomerOrderNo;
      });
      this.onBindData();
      this.CalcStkOutAmoutAndGrossProfit(true);
      this.StkOut.NewCustomerLabel = "淘宝";
    },

    subStrStkValue(value) {
      let newValue = "";
      if (!this.hasValue(value)) return;
      if (value.indexOf("颜色分类:") > -1) {
        newValue = value.substr(5);
      } else if (value.indexOf("颜色:") > -1) {
        newValue = value.substr(3);
      }
      return newValue;
    },

    // 添加一行
    addNewRow() {
      this.$refs.flexGrid.addRow(true); // DefaultUnit
      // 新添加的那行，单位使用默认值
      this.setNewRowDefaultConfig();
    },

    // setNewRowDefaultConfig
    setNewRowDefaultConfig() {
      if (this.itemSource && this.itemSource.length > 0) {
        var last = this.itemSource[this.itemSource.length - 1] || {};
        if (last.Unit === null || last.Unit === undefined || last.Unit === "") {
          last.Unit = this.defaultUnit;
        }
      }
    },
    onExchangeChange() {
      this.CalcStkOutAmoutAndGrossProfit(false);
    },
  },
  mounted() {
    this.systemConfig.hasFinance =
      this.getCompanySetupValue("HasFinance") === "1";
    this.$nextTick(() => {
      if (this.editType === "addNew" && this.autoAddNewRow) {
        this.addNewRow();
      }
    });
  },
  async created() {
    let innerHeight = window.innerHeight;
    let lastHeight = innerHeight - 40;
    if (innerHeight > 800) {
      lastHeight = 800;
    } else if (innerHeight > 768 && innerHeight <= 800) {
      lastHeight = innerHeight;
    }
    if (lastHeight % 2 !== 0) {
      lastHeight = lastHeight - 1;
    }
    this.dialogbox.height = lastHeight + "px";

    this.bankAccountNameList = _.map(
      await this.getBankAccountListAsync,
      (opt) => {
        return {
          key: opt.key,
          value: opt.value + " (" + opt.data + ")",
        };
      }
    );
  },
};
</script>
<style lang="scss">
.dialog-stkoutedit-box {
  > .znl-dialog {
    max-height: 1024px !important;
  }

  .grid-data-stkoutedit-box .znl-table_base-case {
    max-height: 178px !important;
    .el-table {
      max-height: 178px !important;
    }
  }

  .znl-dialog__body {
    overflow: auto;
  }

  .znl-dialog.el-dialog .el-dialog__body {
    padding: 0px 15px 30px;
  }

  .flex-between {
    padding-top: 6px;
  }

  .grid-data-stkoutedit-box .has-gutter tr th {
    background: #ebeef5;
  }

  .znl-dialog.el-dialog .el-dialog__header {
    height: 100%;
  }

  .znl-content .base-flex-grid {
    padding: 0;
    background: #fff;
  }

  .el-pagination button,
  .footer .el-pager li {
    background: transparent;
  }
}
</style>

