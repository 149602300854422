<template>
  <div>
    <!-- 匹配弹出窗 - 新增、编辑 -->
    <znl-dialog
      title="库存匹配"
      :visible="visible"
      subhead
      height="640px"
      width="850px"
      class="dialog-item-stock-link-box"
      :close-on-click-modal="false"
      :is-footer-show="true"
      v-loading="dialogLoading"
      element-loading-text="请稍后..."
      @close="val => $emit('close', val)"
    >
      <div class="item-stock-link-list" style="height: 100%">
        <znl-table
          ref="table"
          gridtype="base"
          :showCheck="true"
          :columns="columns"
          :item-source="itemSource"
          :on-page-changed="onBindData"
          :page-size="pageSize"
          :page-index="pageIndex"
          :total-count="totalCount"
          v-loading="saveloading"
          box-class="item-stock-link-list"
          element-loading-text="加载中，请稍后"
          @current-row-change="onCurrentRowChange"
          @select-change="selectChange"
          :is-fields-search="isShowFiedsSearch"
          :search-fields="onSearch"
        >
          <div slot="header" class="v-table-toolbar search-box">
            <el-row class="tips">
              <el-col :span="3">
                <div class="grid-content bg-purple">
                  <img width="80px" :src="taobaoItem.PicUrl" class="model-pic" />
                </div>
              </el-col>
              <el-col :span="21">
                <div class="grid-content bg-purple">
                  {{taobaoItem.Title}}
                  <br />
                  <span>ID: {{taobaoItem.NumIidStr}}</span>
                  <span class="ml5">
                    商家编码：
                    <strong class="red">{{taobaoItem.OuterId}}</strong>
                  </span>
                  <span class="ml5 link-models">
                    关联型号：
                    <!-- <strong>{{linkModels}}</strong> -->
                    <el-tag
                      v-for="stk in linkStocks"
                      :key="stk.StkGuid"
                      closable
                      @close="deleteLink(stk)"
                      type
                    >{{stk.StockModel}}</el-tag>
                    <!-- <span class="el-icon-close" title="点击删除该标签">xxxx</span> -->
                  </span>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <znl-input
                form-type="input"
                placeholder="型号"
                :clearable="true"
                @keyup.enter.native="onSearch()"
                width="140px"
                size="mini"
                layout="left"
                inp-perc="100%"
                :border="true"
                v-model="searchFields.Model"
                type="text"
              ></znl-input>

              <znl-button type="multiple" style-type="mac" class="box-left-btn search-btn">
                <znl-button-menu @click="onSearch()">
                  <i class="iconfont icon-search_ic"></i>
                  <span>搜索</span>
                </znl-button-menu>
                <znl-button-menu :width="20" tip="高级搜索" @click="onShowFiedsSearch()">
                  <i class="iconfont icon-arrow-drop-down_ic"></i>
                </znl-button-menu>
              </znl-button>
              <el-checkbox v-model="isZero" size="small">包含零库存</el-checkbox>
            </el-row>
          </div>
        </znl-table>
      </div>
      <div slot="footer" class="footer">
        <znl-button
          :height="30"
          :width="50"
          style-type="main"
          @click.stop="onSave"
          :disabled="saveloading"
        >保存</znl-button>
        <znl-button :height="30" :width="50" @click.stop="()=>{$emit('close')}">关闭</znl-button>
      </div>
    </znl-dialog>
  </div>
</template>
<script>
const CONFIG = {
  searchURL: "StkStockMini/Search",
  configURL: "StkStock/GetConfig",
  saveUrl: "TaobaoStock/UpdateLinkModel"
};
import { mixin as common } from "~assets/scripts/methods/common";
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";

export default {
  name: "taobao-erp-stock-mapping",
  mixins: [common, getCommonDataMixin],
  components: {},

  data() {
    return {
      dialogLoading: true,
      saveloading: false,
      isShowFiedsSearch: false,
      isZero: false,
      columns: [],
      itemSource: [],
      pageSize: 30,
      pageIndex: 1,
      totalCount: 0,

      searchFields: {
        Model: ""
      },

      // linkModels: '',
      linkStocks: []
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    taobaoItem: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },

  computed: {
    linkModels() {
      let str = "";
      if (this.linkStocks && this.linkStocks.length > 0) {
        str = _.map(this.linkStocks, m => {
          return m.StockModel;
        }).join(", ");
      }
      return str;
    }
  },

  methods: {
    async onInit(config) {
      if (!config) {
        config = await this.isSaveStorage("ItemStockLink", CONFIG.configURL);
      }
      this.dialogLoading = false;
      this.$emit("page-loading", false);

      let columns = this.onInitCols(config.ColumnConfigs);
      this.columns = columns;
      this.$refs.table.init();
      this.$nextTick(() => {
        this.$emit("page-loading", false);
      });
      this.itemSource = [];

      if (
        this.taobaoItem &&
        this.taobaoItem.ItemLinks &&
        this.taobaoItem.ItemLinks.length > 0
      ) {
        _.each(this.taobaoItem.ItemLinks, item => {
          this.linkStocks.push(item);
        });
      }
    },

    onInitCols(columns) {
      this.setHideColumns(columns);
      return columns;
    },
    //  /** **********权限控制start*************/
    setHideColumns(columns) {
      // 成本价权限控制BuyPrice 采购价未税BuyPrice 和TaxBuyPrice 采购价(含税)  和 成本金额TotalCostAmount
      let viewBuyPrice = this.getSpecialResourceByCode("ViewBuyPrice");

      // 成本价权限控制 SalesPrice 销售定价 FollowPrice 建议销售价
      let viewSalesPrice = this.getSpecialResourceByCode("ViewSalesPrice");

      // 处理特殊权限  不看供应商资料(供应商、联系人、电话、手机、地址)
      let noSeeSupplier = this.getSpecialResourceByCode("NoSeeSupplier");

      _.remove(columns, item => {
        if (item.BindField === "StockImage" || item.BindField === "AdoptQty") {
          return true;
        }

        // 不看成本价
        if (!viewBuyPrice) {
          switch (item.BindField) {
            case "BuyPrice": // 未税采购价
            case "TaxBuyPrice": // 含税采购价
            case "TotalCostAmount":
            case "CostAmountInTax":
              return true;
          }
        }
        // 不看销售价
        if (
          !viewSalesPrice &&
          (item.BindField === "SalesPrice" || item.BindField === "FollowPrice")
        ) {
          return true;
        }
        // 不看供应商
        if (noSeeSupplier && item.BindField === "SupplierName") {
          return true;
        }
        // 看备注1 没有这个权限隐藏列
        if (item.BindField === "Remark" && !this.hasRes("ViewRemark1")) {
          return true;
        }
        // 看备注2 没有这个权限隐藏列
        if (item.BindField === "Remark1" && !this.hasRes("ViewRemark2")) {
          return true;
        }
        // 看备注3 没有这个权限隐藏列
        if (item.BindField === "Remark2" && !this.hasRes("ViewRemark3")) {
          return true;
        }
        // 不看替代型号，有这个权限隐藏列
        if (
          item.BindField === "ReplaceModel" &&
          this.hasRes("NoSeeReplaceModel")
        ) {
          return true;
        }
      });
    },
    hasRes(code) {
      return _.includes(this.resourceList, code);
    },

    async onSave() {
      let param = {
        NumIid: this.taobaoItem.NumIidStr,
        OuterId: this.taobaoItem.OuterId,
        ItemLinks: this.linkStocks
      };
      return this.$post(CONFIG.saveUrl, param, data => {
        if (data >= 0) {
          this.$refs.table.cleanEdit();
          this.$message({ message: "保存成功", type: "success" });
          this.onConfirm();
        } else {
          this.$message({ message: "保存失败", type: "error" });
        }
      });
    },

    async onBindData(pageIndex = 1) {
      this.dialogLoading = true;

      let filterFields = {
        // 外部搜索条件
        isZero: this.isZero // 零库存
      };
      let param = _.extend(
        {},
        {
          fieldWhereString: JSON.stringify(this.searchFields),
          pageIndex: pageIndex,
          pageSize: this.pageSize
        },
        filterFields
      );

      return await this.$post(CONFIG.searchURL, param, (data, logic) => {
        if (logic.code === 200) {
          this.itemSource = data.ResultList || [];
          this.totalCount = data.TotalCount;
        }
      }).finally(() => {
        setTimeout(() => {
          this.dialogLoading = false;
        }, 300);
      });
    },

    async onSearch(params) {
      if (this.hasValue(params)) {
        this.searchFields = _.extend({}, this.searchFields, params);
      }
      return this.onBindData(1);
    },

    onCurrentRowChange(row) {
      // let index = _.findIndex(this.linkStocks, m => m.StkGuid === row.StkGUID)
      // if (index > -1) {
      //   // 存在，则移除
      //   this.linkStocks.splice(index, 1);
      // } else {
      //   this.linkStocks.push({
      //     StkGuid: row.StkGUID,
      //     StockModel: row.Model,
      //     NumIidStr: this.taobaoItem.NumIidStr
      //   })
      // }
    },

    selectChange(rows) {
      // if (this.linkStocks.length <= 0) {
      //   this.linkStocks = _.map(rows, row => {
      //     return {
      //       NumIidStr: this.taobaoItem.NumIidStr,
      //       StkGuid: row.StkGUID,
      //       StockModel: row.Model
      //     }
      //   })
      // } else {
      //   //过滤当前页数据
      //   this.selectedRows = _.filter(this.selectedRows, a => {
      //     return a.pageIndex != this.pageIndex;
      //   })
      //   //添加当前页数据
      //   this.selectedRows = this.selectedRows.concat(rows);
      //   //从小到大 排序
      //   this.selectedRows.sort(function (a, b) {
      //     return a.pageIndex - b.pageIndex
      //   });
      // }

      let stocks = _.clone(this.linkStocks);
      this.linkStocks = [];

      _.each(rows, row => {
        let index = _.findIndex(stocks, l => l.StkGuid === row.StkGUID);
        if (index === -1) {
          stocks.push({
            pageIndex: this.pageIndex,
            NumIid: this.taobaoItem.NumIidStr,
            StkGuid: row.StkGUID,
            StockModel: row.Model,
            NumIidStr: this.taobaoItem.NumIidStr,
            Brand: row.Brand,
            Packaging: row.Packaging,
            MakeYear: row.MakeYear
          });
        }
      });

      // 移除
      _.remove(stocks, m => {
        if (m.pageIndex == this.pageIndex) {
          let index = _.findIndex(rows, row => row.StkGUID === m.StkGuid);

          return index === -1;
        }
        return false;
      });

      _.each(stocks, m => {
        if (m.pageIndex === this.pageIndex) {
          _.remove();
        }
      });
      this.linkStocks = stocks;
      // this.$refs.table.init();
    },

    // 删除关联(页面删除，最终要点击保存)
    deleteLink(stock) {
      console.log(stock);
      this.linkStocks.splice(this.linkStocks.indexOf(stock), 1);
      this.$message({
        message: "移除了型号[" + stock.StockModel + "], 点击“保存”后生效",
        type: "info"
      });
    },

    onConfirm() {
      this.$emit("confirm", true);
    },
    onShowFiedsSearch() {
      if (!this.isShowFiedsSearch) {
        this.isShowFiedsSearch = true;
      } else {
        this.isShowFiedsSearch = false;
      }
    }
  },

  async mounted() {
    setTimeout(() => {
      this.onInit();
    }, 500);
  }
};
</script>
<style lang="scss">
.dialog-item-stock-link-box {
  .link {
    font-size: 12px;
    text-decoration: underline;
    margin-left: 5px;
  }
  .model-pic {
    max-height: 80px;
  }
  .tips {
    font-size: 12px;
    height: 85px;
    max-height: 85px;
    overflow: auto;
  }
  .text-right {
    text-align: right;
  }
  .red {
    color: red;
  }
  .link-models {
    .el-tag {
      height: 22px;
      padding: 0 5px;
      line-height: 22px;
      margin-right: 3px;
    }
  }
}
</style>
